<template>
    <router-view v-slot="{ Component }">
        <transition name="slide-fade">
            <suspense timeout="0">
                <template #default>
                    <component :is="Component" :key="$route.path" />
                </template>
                <template #fallback>
                    <!-- loading indikator in center of element -->
                    <div class="w-full h-screen my-auto flex items-center justify-center">
                        <Loader2 class="mr-2 h-10 w-10 animate-spin" />
                    </div>
                </template>
            </suspense>
        </transition>
    </router-view>
</template>

<script setup lang="ts">
import { useHead } from '@unhead/vue'
import { Loader2 } from "lucide-vue-next";

useHead({
    title: 'Invoice',
    meta: [
        {
            name: 'description',
            content: 'Invoice'
        },
        {
            name: 'keywords',
            content: 'Invoice'
        },
        {
            name: 'viewport',
            content: 'initial-scale=1, minimum-scale=1, maximum-scale=5, user-scalable=no, width=device-width'
        }
    ]
})
</script>
