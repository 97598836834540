import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useLocales } from './Locale'

export const useSearch = defineStore('useSearch', () => {
    const lowestPrice = ref(0)
    const highestPrice = ref(3000)
    let minPrice = ref(400)
    let maxPrice = ref(2600)
    let priceRange = ref([minPrice.value, maxPrice.value])
    const min = ref<any>(null)
    const max = ref<any>(null)
    const category_data = ref<any>(null)
    const series_data = ref<any>(null)
    const character_data = ref<any>(null)
    const condition_data = ref<any>(null)
    const other_data = ref<any>(null)
    const other_data_selected = ref<any>(null)
    const product_data = ref<any>(null)
    const pageTitle = ref('All Product')
    const collection_data = ref<any>(null)
    const sort = ref<string>('relevant')
    const filterModal = ref<boolean>(false)
    const q = ref<string>()
    const totalProduct = ref<number>(0)

    // computed data
    const getSelectedCategories = (categories: any[]): any[] => {
        let selectedCategories: any[] = [];

        categories.forEach((category: any) => {
            if (category.selected) {
                selectedCategories.push(category);
            }
            if (category.children && category.children.length > 0) {
                selectedCategories = selectedCategories.concat(getSelectedCategories(category.children));
            }
        });

        return selectedCategories;
    };

    const selectedCategory = computed(() => {
        const filtered_category = getSelectedCategories(category_data.value?.data || []);
        const join_id_category = filtered_category
            ?.map((item: any) => item.id)
            .join('+');
        return join_id_category;
    });

    const selectedCategoryUrl = computed(() => {
        const filtered_category = getSelectedCategories(category_data.value?.data || []);
        const join_id_category = filtered_category
            ?.map((item: any) => item.slug)
            .join('+');
        return join_id_category;
    });

    const selectedSeriesUrl = computed(() => {
        const filtered_series = series_data.value?.data?.filter(
            (item: any) => item.selected
        )
        const join_id_series = filtered_series
            ?.map((item: any) => item.slug)
            .join('+')
        return join_id_series
    })

    const selectedCharacterUrl = computed(() => {
        const filtered_character = character_data.value?.data?.filter(
            (item: any) => item.selected
        )
        const join_id_character = filtered_character
            ?.map((item: any) => item.slug)
            .join('+')
        return join_id_character
    })

    const selectedConditionUrl = computed(() => {
        const filtered_condition = condition_data.value?.data?.filter(
            (item: any) => item.selected
        )
        const join_id_condition = filtered_condition
            ?.map((item: any) => item.name)
            .join('+')
        return join_id_condition
    })

    const queryUrl = computed(() => {
        const category = selectedCategoryUrl.value
            ? {
                categories: selectedCategoryUrl.value
            }
            : {}
        const series = selectedSeriesUrl.value
            ? { series: selectedSeriesUrl.value }
            : {}

        const character = selectedCharacterUrl.value
            ? { characters: selectedCharacterUrl.value }
            : {}

        const condition = selectedConditionUrl.value
            ? { conditions: selectedConditionUrl.value }
            : {}

        const other = other_data_selected.value
            ? { other: other_data_selected.value }
            : {}

        const minimum = min.value ? { min: min.value } : {}
        const maximum = max.value ? { max: max.value } : {}
        const param = {
            ...category,
            ...series,
            ...character,
            ...condition,
            ...other,
            ...minimum,
            ...maximum,
            sort: sort.value,
            q: q.value
        }
        return param
    })

    const queryApi = computed(() => {
        const param = {
            categories: selectedCategory.value,
            series: selectedSeriesUrl.value,
            characters: selectedCharacterUrl.value,
            conditions: selectedConditionUrl.value,
            other: other_data_selected.value,
            min: min.value,
            max: max.value,
            sort: sort.value,
            q: q.value
        }
        return param
    })

    const searchCollection = async (slug: string) => {
        try {
            const { data } = await window.axios.get(
                `/api/collections/${slug}?lang=${useLocales().isLang}`
            )
            collection_data.value = data
            product_data.value = data.ten_products
            pageTitle.value = data.title
        } catch (error) {
            console.log(error)
        }
    }

    const setSortMode = (mode: string) => {
        sort.value = mode
    }

    // search category
    const searchCategory = async (slug: string) => {
        await getCategoryFilter()

        const category = category_data.value.data?.find(
            (item: any) => item.slug === slug
        )
        if (category) {
            console.log(category.id)
            pageTitle.value = 'Product Category - ' + category.name

            try {
                const { data } = await window.axios.get(
                    `/api/search?categories=${category.id}`
                )
                product_data.value = data.data
            } catch (error) {
                console.log(error)
            }
        } else {
            console.log('not found')
        }
    }

    const getChildCategory = (item: any) => {
        return item.children.map((x: any) => {
            return {
                id: x.id.toString(),
                name: x.name,
                total_products: x.total_products,
                selected: false,
                slug: x.slug,
                children: getChildCategory(x)
            }
        }).filter(function (element: any) {
            return element !== undefined;
        })
    }

    // get category data
    const getCategoryFilter = async () => {
        try {
            const { data } = await window.axios.get(`/api/search/list/category`)
            const new_data = data.data.map((item: any) => {
                return {
                    id: item.id.toString(),
                    name: item.name,
                    total_products: item.total_products,
                    selected: false,
                    slug: item.slug,
                    children: getChildCategory(item)
                }
            })

            category_data.value = {
                data: new_data,
                total: data.total
            }
        } catch (error) {
            console.log(error)
        }
    }

    // get series data
    const getSeriesFilter = async () => {
        try {
            const { data } = await window.axios.get(`/api/search/list/series`)
            const new_data = data.data.map((item: any) => {
                return {
                    ...item,
                    selected: false
                }
            })
            series_data.value = { data: new_data, total: data.total }
        } catch (error) {
            console.log(error)
        }
    }

    // get character data
    const getCharacterFilter = async () => {
        try {
            const { data } = await window.axios.get(
                `/api/search/list/character`
            )
            const new_data = data.data.map((item: any) => {
                return {
                    ...item,
                    selected: false
                }
            })
            character_data.value = { data: new_data, total: data.total }
        } catch (error) {
            console.log(error)
        }
    }

    // get condition data
    const getConditionFilter = async () => {
        try {
            const { data } = await window.axios.get(
                `/api/search/list/condition`
            )
            const new_data = data.data.map((item: any) => {
                return {
                    ...item,
                    selected: false
                }
            })
            condition_data.value = { data: new_data, total: data.total }
        } catch (error) {
            console.log(error)
        }
    }

    // get other data
    const getOtherFilter = async () => {
        try {
            const { data } = await window.axios.get(`/api/search/list/other`)
            other_data.value = data
        } catch (error) {
            console.log(error)
        }
    }

    // get price range limit
    const getPriceRange = async () => {
        try {
            const { data } = await window.axios.get(
                `/api/search/list/price?currency=${useLocales().isLang === 'en' ? 'USD' : 'IDR'
                }`
            )

            const percentageMax = Math.round(data.data.max * 0.1)

            const minRound = Math.round(Number(data.data.min) + percentageMax)
            const maxRound = Math.round(data.data.max - percentageMax)
            minPrice.value = minRound
            maxPrice.value = maxRound

            lowestPrice.value = Math.round(data.data.min)
            highestPrice.value = Math.round(data.data.max)

            priceRange.value = [minRound, maxRound]
        } catch (error) {
            console.log(error)
        }
    }

    const getAllProduct = async () => {
        try {
            const { data } = await window.axios.get(
                `/api/products?local=${useLocales().isLang}`
            )
            product_data.value = data.data
            pageTitle.value = 'All Product'
        } catch (error) {
            console.log(error)
        }
    }

    const setFilterByUrl = async (query: any) => {
        const keys = Object.keys(query)

        keys.forEach((key: any) => {
            const value = query[key]
            value.split('+').forEach((valueSplit: any) => {
                if (key === 'categories') {
                    const selectMatchingCategories = (categories: any[]) => {
                        categories.forEach((category: any) => {
                            if (category.slug === valueSplit) {
                                category.selected = true;
                            }
                            // Recursively check and update children
                            if (category.children && category.children.length > 0) {
                                selectMatchingCategories(category.children);
                            }
                        });
                    };

                    // Apply to the root categories
                    selectMatchingCategories(category_data.value?.data || []);
                }

                if (key === 'series') {
                    const series = series_data.value?.data?.find(
                        (item: any) => item.slug === value
                    )
                    if (series) {
                        series.selected = true
                    }
                }

                if (key === 'characters') {
                    const character = character_data?.value.data?.find(
                        (item: any) => item.slug === value
                    )
                    if (character) {
                        character.selected = true
                    }
                }

                if (key === 'conditions') {
                    const condition = condition_data?.value.data?.find(
                        (item: any) => item.name === value
                    )
                    if (condition) {
                        condition.selected = true
                    }
                }

                if (key === 'other') {
                    const other = other_data.value?.data?.find(
                        (item: any) => item.name === value
                    )
                    if (other) {
                        other_data_selected.value = other.name
                    }
                }

                if (key === 'min') {
                    min.value = value
                    minPrice.value = value
                    priceRange.value = [value, maxPrice.value]
                }

                if (key === 'max') {
                    max.value = value
                    maxPrice.value = value
                    priceRange.value = [minPrice.value, value]
                }

                if (key === 'q') {
                    q.value = value
                }
            })
        })
    }

    const getSearchProduct = async () => {
        try {
            const { data } = await window.axios.get(`/api/search`, {
                params: {
                    ...queryApi.value,
                    sort: sort.value,
                    currency: useLocales().isLang === 'en' ? 'USD' : 'IDR',
                    page: 1
                }
            })
            product_data.value = data.data
            totalProduct.value = data.total
            pageTitle.value = 'All Product'
        } catch (error) {
            console.log(error)
        }
    }

    const getMoreProduct = async (page: number) => {
        try {
            const { data } = await window.axios.get(`/api/search`, {
                params: {
                    ...queryApi.value,
                    sort: sort.value,
                    currency: useLocales().isLang === 'en' ? 'USD' : 'IDR',
                    page: page
                }
            })
            product_data.value.push(...data.data)
            pageTitle.value = 'All Product'
        } catch (error) {
            console.log(error)
        }
    }

    // Clear Filter
    const clearFilter = () => {
        category_data.value.data?.forEach((item: any) => {
            item.selected = false
        })

        series_data.value.data?.forEach((item: any) => {
            item.selected = false
        })

        character_data.value.data?.forEach((item: any) => {
            item.selected = false
        })

        condition_data.value.data?.forEach((item: any) => {
            item.selected = false
        })

        other_data_selected.value = null
        min.value = null
        max.value = null

        const percentageMax = Math.round(highestPrice.value * 0.1)

        const minRound = Math.round(lowestPrice.value + percentageMax)
        const maxRound = Math.round(highestPrice.value - percentageMax)

        minPrice.value = minRound
        maxPrice.value = maxRound

        priceRange.value = [minRound, maxRound]
    }

    return {
        min,
        max,
        lowestPrice,
        highestPrice,
        minPrice,
        maxPrice,
        category_data,
        series_data,
        character_data,
        condition_data,
        other_data,
        product_data,
        collection_data,
        pageTitle,
        other_data_selected,
        priceRange,
        sort,
        filterModal,
        totalProduct,

        selectedCategory,
        selectedCategoryUrl,
        selectedSeriesUrl,
        selectedCharacterUrl,
        queryUrl,
        queryApi,

        setFilterByUrl,
        getSearchProduct,
        searchCategory,
        searchCollection,
        getCategoryFilter,
        getSeriesFilter,
        getCharacterFilter,
        getConditionFilter,
        getOtherFilter,
        getPriceRange,
        getAllProduct,
        clearFilter,
        setSortMode,
        getMoreProduct
    }
})
