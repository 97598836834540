<template>
    <Navbar />
    <router-view v-slot="{ Component }">
        <transition name="fade">
            <suspense timeout="0">
                <template #default>
                    <!-- <component :is="Component" :key="$route.path" /> -->
                    <LoadHome />
                </template>
                <template #fallback>
                    <LoadHome />
                </template>
            </suspense>
        </transition>
    </router-view>
    <Foot />
</template>

<script setup>
import LoadHome from '@/Components/Skeleton/LoadHome.vue';
import Foot from '@/Components/Section/Foot.vue';
import Navbar from '@/Components/Section/Navbar.vue';
import { useHead, useSeoMeta } from '@unhead/vue'

const setting = ref()
const seo = ref()

const meta = computed(() => {
    return {
        title: () => seo.value?.title ? `${seo.value?.title} | Xiaoken Shop` : 'Xiaoken Shop',
        meta: [
            {
                name: 'description',
                content: () => seo.value?.description ? `${seo.value?.description.replace(/(<([^>]+)>)/ig, '')}` : '-',
            },
            {
                name: 'keywords',
                content: 'xiaoken, xiaoken shop',
            },
            {
                name: 'Author',
                content: 'Xiaoken',
            },
        ],
    }
})

try {
    const resSeo = await axios.get('/api/setting/seo')
    const resSetting = await axios.get('/api/setting/general')

    if (resSeo.status === 200) {
        seo.value = resSeo.data
        console.log(seo.value)
    }

    if (resSetting.status === 200) {
        setting.value = resSetting.data
        console.log(setting.value)
    }
} catch (error) {
    console.log(error)
}

useHead({
    title: () => product_data.value?.product_name ? `${product_data.value?.product_name} | Xiaoken Shop` : 'Xiaoken Shop',
    meta: [
        {
            name: 'description',
            content: () => product_data.value?.product_description ? `${product_data.value?.product_description.replace(/(<([^>]+)>)/ig, '')}` : '-',
        },
        {
            name: 'keywords',
            content: 'xiaoken, xiaoken shop',
        },
        {
            name: 'Author',
            content: 'Xiaoken',
        },
    ],
})

useSeoMeta({
    title: () => product_data.value?.product_name ? `${product_data.value?.product_name} | Xiaoken Shop` : 'Xiaoken Shop',
    description: () => product_data.value?.product_description ? `${product_data.value?.product_description.replace(/(<([^>]+)>)/ig, '')}` : '-',
    ogDescription: () => product_data.value?.product_description ? `${product_data.value?.product_description.replace(/(<([^>]+)>)/ig, '')}` : '-',
    ogTitle: () => product_data.value?.product_name ? `${product_data.value?.product_name} | Xiaoken Shop` : 'Xiaoken Shop',
    ogImage: () => product_data.value?.thumbnail,
    ogUrl: () => window.location.href,
    ogSiteName: 'Xiaoken Shop',
    ogType: 'website',
    ogLocale: 'id_ID',

    twitterCard: 'summary_large_image',
    twitterTitle: () => product_data.value?.product_name ? `${product_data.value?.product_name} | Xiaoken Shop` : 'Xiaoken Shop',
    twitterDescription: () => product_data.value?.product_description ? `${product_data.value?.product_description.replace(/(<([^>]+)>)/ig, '')}` : '-',
    twitterImage: () => product_data.value?.thumbnail,
    twitterImageAlt: () => product_data.value?.product_name ? `${product_data.value?.product_name} | Xiaoken Shop` : 'Xiaoken Shop',
})
</script>

<style>
.fade-enter-active {
    transition: opacity 1s ease;
}

.fade-leave-active {
    transition: opacity 0s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
