<template>
    <section class="relative">
        <div class="container pb-16">

            <LoadItemText class="font-bold text-2xl mb-6">
                Loading News
            </LoadItemText>

            <swiper-container ref="news" init="false">
                <swiper-slide v-for="(item, index) in 9" :key="index" class="px-1 pb-8">
                    <LoadNewsCard />
                </swiper-slide>
            </swiper-container>

        </div>
        <div class="absolute w-full h-1/2 bottom-0 left-0 bg-gradient-to-l from-theme1 to-theme2"></div>
    </section>
</template>

<script setup lang="ts">
import LoadItemText from './LoadItemText.vue';
import LoadNewsCard from './LoadNewsCard.vue';
import { register, type SwiperContainer } from 'swiper/element/bundle';
import { SwiperOptions } from 'swiper/types';
import { onMounted, ref } from "vue";
register();
const news = ref<SwiperContainer>();

onMounted(() => {
    const params: SwiperOptions | null = {
        autoplay: {
            delay: 2000,
        },
        navigation: {
            nextEl: '.next-news',
            prevEl: '.prev-news',
        },
        spaceBetween: 15,
        slidesPerView: 1,
        slidesPerGroup: 1,
        breakpoints: {
            640: {
                slidesPerView: 2,
            },
            1024: {
                slidesPerView: 3,
            },
        },
    }

    Object.assign(news.value as SwiperContainer, params)
    news.value?.initialize();
});
</script>