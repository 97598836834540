import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useToast } from '@/Components/ui/toast'
import { useLocales } from '@/Store/Locale'

export const useNavbarSearch = defineStore('useNavbarSearch', () => {
    const local = useLocales()

    type ListType = {
        id: number,
        product_name: string,
        product_slug: string,
        thumbnail: string
    }
    const List = ref<ListType[]>([])
    const isFetching = ref<boolean>(false)
    const isTyping = ref<boolean>(false)
    const ListHistory = ref<string[]>([])
    let timeout: any = null

    const saveHistoryToLocalStorage = () => {
        localStorage.setItem('search_history', JSON.stringify(ListHistory.value))
    }

    const addToHistory = async (search: string) => {
        try {
            if(!search) return

            if(ListHistory.value.length > 5) {
                ListHistory.value.pop()
            }

            if(ListHistory.value.includes(search)) {
                ListHistory.value = ListHistory.value.filter(item => item !== search)
            }

            ListHistory.value.unshift(search)
            saveHistoryToLocalStorage()
        } catch (error) {
            useToast().toast({
                title: 'Oops... Something went wrong!',
                description: 'failed to history add to user database!',
                variant: 'destructive',
                duration: 3000,
            })
        }
    }

    const getSearchData = async (search: string) => {
        try {
            const { data } = await window.axios.get(`/api/search/item`, {
                params: {
                    q: search,
                    limit: 3,
                    lang: local.isLang
                }
            })

            List.value = data.data
        } catch (error) {
            useToast().toast({
                title: 'Oops... Something went wrong!',
                description: 'failed to fetch data!',
                variant: 'destructive',
                duration: 3000,
            })
        }

        isFetching.value = false
    }

    const searchHandler = async (search: string) => {
        if(!search) {
            List.value = []
            return
        }

        isTyping.value = true
        isFetching.value = true

        if(timeout) clearTimeout(timeout)

        timeout = setTimeout(async () => {
            await getSearchData(search)
            isTyping.value = false
        }, 500)
    }

    const clearHistory = () => {
        ListHistory.value = []
        saveHistoryToLocalStorage()
    }

    const clearList = () => {
        List.value = []
    }

    const getHistoryFromLocalStorage = () => {
        const history = localStorage.getItem('search_history')

        if(history) {
            ListHistory.value = JSON.parse(history)
        }
    }

    return {
        List,
        isFetching,
        isTyping,
        ListHistory,

        searchHandler,
        addToHistory,
        clearHistory,
        clearList,
        getHistoryFromLocalStorage
    }
})
