import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

export const useLocales = defineStore('useLocale', () => {
    // state
    const lang = ref<string>(localStorage.getItem('lang') || 'en')

    // getter
    const isLang = computed(() => lang.value)

    // action
    const setLang = (value: string) => {
        localStorage.setItem('lang', value)
        lang.value = value
    }

    return {
        // state
        lang,

        // getter
        isLang,

        // action
        setLang
    }
})