import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useLocales } from './Locale'

export const useHome = defineStore('useHome', () => {
    const banner_data = ref<any>([])
    const shorts_data = ref<any>([])
    const fetch_banner = ref<boolean>(true)
    const fetch_short = ref<boolean>(true)
    const setting_data = ref<any>(null)

    const getSetting = async () => {
        try {
            const response = await window.axios.get('api/setting/general', {
                params: {
                    lang: useLocales().isLang
                }
            })
            setting_data.value = response.data.data
        } catch (error) {
            console.error(error)
        }
    }

    const getBanner = async () => {
        try {
            const response = await window.axios.get(
                `api/banners?local=${useLocales().isLang}`
            )
            const data = response.data

            banner_data.value = data
            fetch_banner.value = false
        } catch (error) {
            console.error(error)
        }
    }

    const getShortsData = async () => {
        try {
            const { data } = await window.axios.get('/api/shorts?limit=10')
            shorts_data.value = data.data

            fetch_short.value = false
        } catch (error) {
            console.error(error)
        }
    }

    return {
        banner_data,
        shorts_data,
        fetch_banner,
        fetch_short,
        setting_data,

        getBanner,
        getShortsData,
        getSetting
    }
})
