<template>
    <div @click="redirectTo(search)"
        class="flex flex-row items-center cursor-pointer justify-left gap-3 px-3 py-2 hover:bg-slate-100">
        <Search :size="20" class="text-slate-300" /> {{ search || "Your Search will show here" }}
    </div>
</template>

<script setup lang="ts">
import { Search } from "lucide-vue-next";
import { useRouter } from 'vue-router';

import { useNavbarSearch } from '@/Store/NavbarSearch';

defineProps<{ search: string }>()

const store = useNavbarSearch();
const router = useRouter();

const redirectTo = (search: string) => {
    store.addToHistory(search);

    // redirect to search page
    router.push({
        name: 'Search',
        query: {
            q: search,
        }
    });
}
</script>
