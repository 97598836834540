<template>
    <!-- <div class="grow h-full"> -->
    <div v-on-click-outside="handleBlur" class="grow h-full">
        <div :class="{ '!w-full': isFocused }"
            class="w-full group sm:w-3/4 transition-all duration-500 mx-auto flex flex-col h-full relative items-center font-normal">

            <input type="text" v-model="search" @focus="isFocused = true" @keyup="store.searchHandler(search)"
                @keyup.enter="doSearch(search)" class="grow-0 peer w-full border-none bg-slate-50 ring-1 ring-slate-200 placeholder-slate-400
                        rounded-full text-sm pl-10 pr-4 py-1.5 transition-all duration-500 shadow
                        focus:bg-white focus:ring-theme1
                        " :placeholder='$t("navbar.search")'>

            <div class="absolute top-1.5 left-3 text-slate-400 peer-focus:text-theme1 transition-all">
                <Search :size="20" />
            </div>

            <!-- Dropdown -->
            <div :class="{ '!max-h-[240px] !translate-y-2 !border': isFocused }" class="sm:absolute left-0 z-10 w-full rounded-xl sm:shadow-lg top-full bg-white flex flex-col overflow-hidden sm:transition-all sm:duration-500 sm:max-h-0 sm:delay-200 grow max-h-0
                    ">
                <span class="px-4 py-2">Keyword</span>
                <div
                    class="scrollbar-thin scrollbar-track-slate-50 scrollbar-thumb-slate-200 h-full flex flex-col overflow-y-auto text-slate-600">
                    <SearchText v-if="search" :search="search" />
                    <SearchText v-else v-for="item in ListHistory || []" :key="item" :search="item" />
                </div>
                <SearchItem />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { vOnClickOutside } from '@vueuse/components'
import { Search } from "lucide-vue-next";
import { ref } from 'vue';
import SearchText from "./SearchText.vue";
import SearchItem from "./SearchItem.vue";
import { useNavbarSearch } from '@/Store/NavbarSearch';
import { storeToRefs } from 'pinia';
import { useRouter, useRoute } from 'vue-router';

const emit = defineEmits(["focus"]);

const store = useNavbarSearch();
const router = useRouter();
const route = useRoute();
const { ListHistory } = storeToRefs(store);
const isFocused = ref(false);
const search = ref("");

if (route.query.q) {
    search.value = route.query.q as string;
}

const handleSearch = () => {
    isFocused.value = true;
    // emit("focus", isFocused.value);
};

const handleBlur = () => {
    isFocused.value = false;
    // emit("focus", isFocused.value);
    store.clearList();
};

const doSearch = (search: string) => {
    store.addToHistory(search);
    store.clearList();

    // redirect to search page
    router.push({
        name: 'Search',
        query: {
            q: search,
        }
    });
}

store.getHistoryFromLocalStorage();
</script>
