import HomeLayout from './HomeLayout.vue'
import AllProductLayout from './AllProductLayout.vue'
import CartLayout from './CartLayout.vue'
import DefaultLayout from './DefaultLayout.vue'
import DetailProductLayout from './DetailProductLayout.vue'
import ShipmentLayout from './ShipmentLayout.vue'
import InvoiceLayout from './InvoiceLayout.vue'

export default {
    DefaultLayout,
    HomeLayout,
    DetailProductLayout,
    AllProductLayout,
    CartLayout,
    ShipmentLayout,
    InvoiceLayout
}
