<template>
    <component :is="layout || 'div'">
        <router-view />
    </component>
    <Toaster :duration="2000" />
</template>

<script setup lang="ts">
import { provide, shallowRef } from 'vue';
import router from './routes'
import layouts from './Layouts/index.js'
import type { RouteLocationNormalized } from 'vue-router'
import Toaster from '@/Components/ui/toast/Toaster.vue'
import { getAge } from '@/lib/utils'
// @ts-ignore
import { useAuth } from "vue3-auth";

const { user } = useAuth();
const layout = shallowRef<any>('div')
let layoutNames = Object.keys(layouts);

router.afterEach((to: RouteLocationNormalized) => {
    const key = layoutNames[layoutNames.indexOf(to.meta.layout)] as keyof typeof layouts;
    layout.value = layouts[key] || "div"
})

router.beforeResolve((to, from, next) => {
    if (to.meta.matureCheck) {
        if (user.value && getAge(user.value?.birthday) < 18 && !isNaN(getAge(user.value?.birthday))) {
            next('/');
        }
    } else {
        next();
    }

    next()
})

provide('app:layout', layout)
</script>
