export const Lang = {
    en: {
        navbar: {
            lang: 'Language',
            list: 'Menu List',
            home: 'Home',
            product: 'Product',
            category: 'Category',
            series: 'Series',
            character: 'Character',
            search: 'Search Product..',
            cart: 'Cart',
            login: 'Login',
            register: 'Register',
            dropdown: {
                request: 'Request Order',
                list_order: 'List Order',
                wishlist: 'Wishlist',
                profile: 'Profile',
                address: 'Address',
                logout: 'Logout',
                cart: 'My Cart',
                cart_detail: 'More Detail'
            },
            message: 'Message',
            empty: {
                title: 'Cart Empty',
                desc: 'Time to add more Product Now!'
            }
        },
        section: {
            custom: {
                heading: 'World Wide',
                title: 'Custom Request Order',
                description:
                    'We Accept Import Orders from Anywhere, with Japan as the Main Country. Contact for More Info!!',
                button: 'More Info'
            },
            categories: {
                title: 'Categories'
            },
            news: {
                title: 'News & Events'
            },
            view: 'View All',
            read: 'Readmore'
        },
        login: {
            title: 'Login',
            email: 'Email',
            password: 'Password',
            cta: 'Sign In',
            remember: 'Remember Me',
            forgot: 'Forgot Password?',
            or: 'Or Login With',
            register: 'Have Account?',
            register_desc:
                'Register Now And Get Access To Our Exclusive Products!!',
            register_cta: 'Register Now',
            account: 'Account'
        },
        register: {
            title: 'Register',
            first: 'First Name',
            last: 'Last Name',
            email: 'Email',
            phone: 'Phone Number',
            password: 'Password',
            confirm: 'Confirm Password',
            cta: 'Sign Up',
            login: 'Have Account?',
            login_desc: 'Login to Continue Shopping',
            login_cta: 'Login Here'
        },
        product: {
            title: 'All Product',
            price: 'Price',
            filter: {
                newest: 'Newest',
                most_buy: 'Most Buy',
                popular: 'Popular',
                discount: 'Discount',
                pre_order: 'Pre-Order',
                ready_stock: 'Ready Stock',
                lowest_price: 'Lowest Price',
                highest_price: 'Highest Price',
            },
            cta: 'Load More Product',
            empty: {
                title: 'Product Not Available',
                desc: 'We are sorry, the product you are looking for is not available at the moment'
            }
        },
        shorts: {
            title: 'Checkout Our Shorts',
            cta: 'View All Shorts'
        },
        search: {
            filter: {
                title: 'Filter Product',
                close: 'Close',
                min: 'Min Price',
                max: 'Max Price',
                category: 'Category',
                series: 'Series',
                character: 'Character',
                condition: 'Condition',
                other: 'Other',
                stock: 'Ready Stock',
                order: 'Pre-Order',
                apply: 'Apply Filter',
                clear: 'Clear Filter'
            },
            sort: {
                title: 'Sort',
                relevant: 'Relevant',
                newest: 'Newest',
                price_high: 'Highest Price',
                price_low: 'Lowest Price'
            }
        },
        request: {
            title: 'Custom Request Order',
            search: 'Search Order',
            product_url: 'Please provide the link to the item you desire!',
            brief: 'Hmm, What is that item?',
            brief_placeholder:
                'Please fill in the field below so Xiaoken can understand the item you want.',
            name: 'Product Name',
            name_placeholder: 'Input Product Name',
            price: 'Price',
            quantity: 'Quantity',
            quantity_placeholder: 'Total Quantity',
            shipping: 'Where would you like it to be shipped?',
            notice: 'Notice',
            notice_desc:
                'We will inform the detail about your custom request order in whatsapp such as shipping, rates and the total payment. Please make sure your phone number is correct.',
            cta: 'Send Request',
            slider_title:
                'Shopping from anywhere is as easy as copying the link!',
            slider_desc:
                'You can shop for items from various marketplaces such as Amazon, Rakuten, and more. Simply copy the link of the item you desire and paste it in the field above.',
            history: 'History',
            guide: 'Guide',
            status: {
                all: 'All',
                approved: 'Approved',
                pending: 'Pending',
                canceled: 'Canceled'
            },
            reset: 'Reset Filter',
            history_number: 'Order Number',
            history_link: 'Product Link',
            history_chat: 'Chat Seller',
            history_empty: 'History Empty',
            history_empty_desc: "No Custom Order Yet, Let's Add Order!!"
        },
        product_page: {
            product: 'Product',
            detail: {
                title: 'Product Details',
                category: 'Category',
                sku: 'SKU',
                min_order: 'Min Order',
                condition: 'Condition',
                condition_new: 'New',
                condition_used: 'Used',
                status: {
                    duration: 'Duration',
                    days: 'Days'
                },
                character: 'Character',
                stock: 'Stock',
                stock_out: 'Out of Stock',
                size: 'Size (L.W.H)',
                weight: 'Weight',
                tags: 'Tags'
            },
            select: 'Select',
            quantity: 'Quantity',
            order_notes: 'Order Notes',
            notes_placeholder: 'Write your order notes here...',
            add_cart: 'Add to Cart',
            wishlist: 'Wishlist',
            checkout_now: 'Checkout Now',
            desc: 'Product Description',
            share: 'Share',
            similar: 'Similar Product',
            recent: 'Recent Viewed Product',
            interest: 'You Might Interested'
        },
        checkout: {
            title: 'Checkout Order',
            add: 'Add Product',
            shipment: 'Shipment',
            payment: 'Payment',
            status: 'Status',
            cart: {
                title: 'Shopping Cart',
                selected: 'Selected',
                empty: 'Your Cart is Empty',
                empty_desc: 'Time to add more Product Now!',
                cta: 'Proceed to Shipment'
            },
            table: {
                product: 'Product',
                variant: 'Variant',
                weight: 'Weight',
                quantity: 'Quantity',
                action: 'Action',
                shopping_total: 'Shopping Total',
                total_weight: 'Total Weight',
                alert: 'Remove Product to Continue',
                notes: 'Notes',
                stock_out: 'Out of Stock',
                back: 'Continue Shopping'
            }
        },
        summary: {
            title: 'Order Summary',
            coupon: 'Coupon Code',
            shop_total: 'Shopping Total',
            ship_cost: 'Shipping Cost',
            insurance: 'Insurance Cost',
            discount: 'Discount',
            pack: "Packing Fee's",
            gov_tax: 'Gov Tax',
            payment_fee: 'Payment Fee',
            grand: 'Grand Total'
        },
        shipment: {
            title: 'Shipment',
            address: 'Shipping Address',
            page: 'My Address',
            empty: 'Your Address is empty',
            empty_desc: 'Please add atleast one address to complete the order',
            card: {
                type: 'Type',
                primary: 'Primary',
                selected: 'Selected',
                receipt: 'Recipient',
                country: 'Country',
                province: 'Province',
                city: 'City',
                address: 'Address',
                detail: 'Detail Address',
                postal: 'Postal Code',
                cta: 'Select Different Address'
            },
            form: {
                list: 'List Address',
                add: 'Add New Address',
                update: 'Update Address',
                type: 'Adddress Type',
                receipt: 'Recipient Name',
                email: 'Recipient Email',
                phone: 'Phone Number',
                country: 'Country',
                province: 'Province',
                city: 'City',
                postal: 'Postal Code',
                address: 'Address',
                detail: 'Building Number / Floor / Unit',
                main: 'Set as Main Address',
                yes: 'Yes',
                no: 'No',
                cta: 'Add Address',
                cta2: 'Update Address',
                notice_email:
                    'Any information regarding the shipment and delivery will be sended to this email',
                notice_phone:
                    'Input using international format, e.g. 628123456789'
            },
            expedition: {
                title: 'Choose Expedition',
                sent: 'Sent From',
                destination: 'Destination to',
                estimate: 'Estimate',
                days: 'Days'
            },
            method: 'Payment Method',
            notes: 'Shipping Notes',
            notes_placeholder: 'Write your shipping notes here...',
            cta: 'Pay Now',
            insurance: {
                title: 'Shipping Insurance',
                active: 'Activate Insurance',
                fee: 'Insurance Fee',
                desc: 'One of the products you buy requires insurance, for safer delivery at',
                yes: 'Yes',
                no: 'No'
            }
        },
        payment: {
            guide: 'Payment Guide',
            title: 'Payment Order',
            close: 'Closed in',
            invoice: 'Check Invoice',
            days: 'Days',
            hour: 'h',
            minute: 'm',
            second: 's',
            expirate: 'Payment Expired',
            notice: 'Thank you for placing an order. We kindly remind you to complete the payment for your order before the upcoming deadline. Dont forget to upload Payment Proof in the box below.',
            upload: 'Upload Payment Proof',
            uploaded: 'Your Payment Proof',
            total: 'Total Payment'
        },
        status: {
            expirate: 'Your Payment time is up',
            expirate_desc:
                'Your payment time has expired, please place a new order',
            expirate_cta: 'Place New Order',
            reject: 'Your Payment is not Valid',
            reject_desc: 'Please upload new payment proof',
            reject_cta: 'Upload Payment Proof',
            pending: 'Your Payment is being Processed',
            pending_desc:
                'Please wait for a moment our team will check your payment',
            pending_cta: 'Buy Another Product',
            paid: 'Congratulations. Your Payment is accepted',
            paid_desc:
                'Thanks for ordering in XiaokenShop. We will process your order as soon as possible.',
            paid_cta: 'Track Order'
        },
        order: {
            title: 'Order History',
            search: 'Search Order',
            empty: 'Your Order List is Empty',
            empty_desc: 'Please Add New Order!',
            cta: 'Add Product',
            status: {
                all: 'All',
                process: 'Process',
                success: 'Completed',
                cancel: 'Canceled',
                failed: 'Failed',
                reset: 'Reset Filter'
            },
            card: {
                price: 'Price',
                shipping: 'Shipping to',
                detail: 'Detail Transaction',
                total: 'Total Payment',
                product: 'Total Product',
                cancel: 'Cancel Order',
                help: 'Order Help',
                pay: 'Pay Now',
                again: 'Order Again'
            },
            detail: {
                title: 'Detail Transaction',
                viewmore: 'View More',
                number: 'Order Number',
                date: 'Order Date',
                order: 'Detail Product',
                shipping: 'Shipping Detail',
                expedition: 'Expedition',
                address: 'Shipping Address',
                payment: 'Payment Detail',
                method: 'Payment Method',
                total: 'Shopping Total',
                shipping_cost: 'Shipping Cost',
                gov: 'Gov Tax',
                payment_fee: 'Payment Fee',
                grand: 'Total Payment',
                discount: 'Discount'
            }
        },
        wishlist: {
            title: 'My Wishlisht',
            empty: 'Your Wishlist is Empty',
            empty_desc: 'Explore our product and find your favorite product!'
        },
        profile: {
            title: 'Update Profile',
            verify: 'Verify account',
            email_not: 'Email Not Verified',
            email_yes: 'Account Verified',
            first: 'First Name',
            last: 'Last Name',
            dob: 'Birthday',
            phone: 'Phone Number',
            password: 'Password',
            confirm: 'Confirm Password',
            cta: 'Update Profile'
        },
        forgot: {
            title: 'Forgot Password?',
            desc: 'Enter your email address and we will send you a link to reset your password.'
        },
        reset: {
            title: 'Reset Password',
            desc: 'Please fill in the field below to reset your password',
            new: 'New Password',
            confirm: 'Confirm New Password',
            cta: 'Change Password'
        },
        footer: {
            address: 'Address',
            shopping: {
                head: 'Shopping',
                home: 'Home',
                request: 'Request Order',
                all_product: 'All Product',
                discount: 'Discount Product'
            },
            about: {
                head: 'About Us',
                about: 'About',
                news: 'News & Event',
                tnc: 'Terms & Conditions',
                privacy: 'Privacy Policy'
            },
            support: {
                head: 'Support',
                shop_guide: 'Shopping Guidelines',
                faq: 'FAQ',
                help: 'Contact Us'
            },
            payment: 'Supported Payment',
            store: 'Our Store',
            follow: 'Follow Us'
        }
    },

    id: {
        navbar: {
            lang: 'Bahasa',
            list: 'Daftar Menu',
            home: 'Beranda',
            product: 'Produk',
            category: 'Kategori',
            series: 'Seri',
            character: 'Karakter',
            search: 'Cari Product..',
            cart: 'Belanja',
            login: 'Masuk',
            register: 'Daftar',
            dropdown: {
                request: 'Pesanan Custom',
                list_order: 'Daftar Pesanan',
                wishlist: 'Favorit',
                profile: 'Profil',
                address: 'Alamat',
                logout: 'Keluar',
                cart: 'Keranjang',
                cart_detail: 'Lihat Detail'
            },
            message: 'Kirim Pesan',
            empty: {
                title: 'Keranjang Kosong',
                desc: 'Waktunya untuk menambahkan lebih banyak Produk!'
            }
        },
        login: {
            title: 'Masuk',
            email: 'Email',
            password: 'Kata Sandi',
            cta: 'Masuk',
            remember: 'Ingat Saya',
            forgot: 'Lupa Kata Sandi?',
            or: 'Atau Masuk Dengan',
            register: 'Belum Punya Akun?',
            register_desc:
                'Daftar Sekarang dan Dapatkan Akses ke Produk Eksklusif Kami!!',
            register_cta: 'Daftar Sekarang',
            account: 'Akun'
        },
        register: {
            title: 'Daftar',
            first: 'Nama Depan',
            last: 'Nama Belakang',
            email: 'Email',
            phone: 'Nomor Telepon',
            password: 'Kata Sandi',
            confirm: 'Konfirmasi Kata Sandi',
            cta: 'Daftar',
            login: 'Sudah Punya Akun?',
            login_desc: 'Masuk untuk Melanjutkan Belanja',
            login_cta: 'Masuk Disini'
        },
        product: {
            title: 'Semua Produk',
            price: 'Harga',
            filter: {
                newest: 'Terbaru',
                most_buy: 'Paling Banyak Dibeli',
                popular: 'Populer',
                discount: 'Diskon',
                pre_order: 'Pre-Order',
                ready_stock: 'Ready Stock',
                lowest_price: 'Harga Terendah',
                highest_price: 'Harga Tertinggi'
            },
            cta: 'Lihat Lebih Banyak',
            empty: {
                title: 'Produk Tidak Tersedia',
                desc: 'Maaf, produk yang Anda cari tidak tersedia saat ini'
            }
        },
        shorts: {
            title: 'Tonton Video Pendek Kami',
            cta: 'Lihat Semua Video'
        },
        search: {
            filter: {
                title: 'Filter Produk',
                close: 'Tutup',
                min: 'Min Harga',
                max: 'Max Harga',
                category: 'Kategori',
                series: 'Seri',
                character: 'Karakter',
                condition: 'Kondisi',
                other: 'Lainnya',
                stock: 'Ready Stock',
                order: 'Pre-Order',
                apply: 'Terapkan Filter',
                clear: 'Hapus Filter'
            },
            sort: {
                title: 'Urutkan',
                relevant: 'Relevan',
                newest: 'Terbaru',
                price_high: 'Harga Tertinggi',
                price_low: 'Harga Terendah'
            }
        },
        section: {
            custom: {
                heading: 'Menerima Pesanan',
                title: 'Custom Seluruh Dunia',
                description:
                    'Kami Menerima Pesanan Import dari Seluruh Dunia, dengan Jepang sebagai Negara Utama. Hubungi Kami untuk Info Lebih Lanjut!!',
                button: 'Info Lebih lanjut'
            },
            categories: {
                title: 'Kategori'
            },
            news: {
                title: 'Berita & Event'
            },
            view: 'Lihat Semua',
            read: 'Selengkapnya'
        },
        request: {
            title: 'Pesanan Custom',
            search: 'Cari Pesanan',
            product_url: 'Silakan berikan link ke item yang Anda inginkan!',
            brief: 'Hmm, barang apa itu?',
            brief_placeholder:
                'Silakan lengkapi data dibawah ini sehingga Xiaoken dapat memahami item yang Anda inginkan.',
            name: 'Nama Produk',
            name_placeholder: 'Input Nama Produk',
            price: 'Harga',
            quantity: 'Jumlah',
            quantity_placeholder: 'Total Jumlah',
            shipping: 'Kemana Kamu Ingin Mengirimkannya?',
            notice: 'Perhatian',
            notice_desc:
                'Kami akan memberitahukan detail pesanan custom Kamu melalui whatsapp seperti pengiriman, tarif dan total pembayaran. Pastikan nomor telepon kamu benar.',
            cta: 'Kirim Pesanan',
            slider_title: 'Belanja dari mana saja semudah menyalin link!',
            slider_desc:
                'Kamu bisa berbelanja item dari berbagai marketplace seperti Amazon, Rakuten, dan lainnya. Cukup salin link item yang kamu inginkan dan tempelkan pada kolom di atas.',
            history: 'Riwayat Pesanan',
            guide: 'Panduan',
            status: {
                all: 'Semua',
                approved: 'Disetujui',
                pending: 'Menunggu',
                canceled: 'Dibatalkan'
            },
            reset: 'Hapus Filter',
            history_number: 'Nomor Pesanan',
            history_link: 'Link Produk',
            history_chat: 'Chat Penjual',
            history_empty: 'Riwayat Kosong',
            history_empty_desc:
                'Belum Ada Pesanan Custom, Yuk Tambahkan Pesanan!!'
        },
        product_page: {
            product: 'Produk',
            detail: {
                title: 'Detail Produk',
                category: 'Kategori',
                sku: 'SKU',
                min_order: 'Min Pesanan',
                condition: 'Kondisi',
                condition_new: 'Baru',
                condition_used: 'Bekas',
                status: {
                    duration: 'Durasi',
                    days: 'Hari'
                },
                character: 'Karakter',
                stock: 'Stok',
                stock_out: 'Stok Habis',
                size: 'Ukuran (P.L.T)',
                weight: 'Berat',
                tags: 'Tags'
            },
            select: 'Pilih',
            quantity: 'Jumlah',
            order_notes: 'Catatan Pesanan',
            notes_placeholder: 'Tulis catatan pesanan kamu disini...',
            add_cart: 'Keranjang',
            wishlist: 'Favorit',
            checkout_now: 'Bayar Sekarang',
            desc: 'Deskripsi Produk',
            share: 'Bagikan',
            similar: 'Produk Serupa',
            recent: 'Produk Terakhir Dilihat',
            interest: 'Kamu Mungkin Tertarik'
        },
        checkout: {
            title: 'Checkout Pesanan',
            add: 'Tambah Produk',
            shipment: 'Pengiriman',
            payment: 'Pembayaran',
            status: 'Status',
            cart: {
                title: 'Keranjang Belanja',
                selected: 'Dipilih',
                empty: 'Keranjang Kamu Kosong',
                empty_desc: 'Waktunya untuk menambahkan lebih banyak Produk!',
                cta: 'Lanjut ke Pengiriman'
            },
            table: {
                product: 'Produk',
                variant: 'Variant',
                weight: 'Berat',
                quantity: 'Jumlah',
                action: 'Aksi',
                shopping_total: 'Total Belanja',
                total_weight: 'Total Berat',
                alert: 'Hapus Produk untuk Melanjutkan',
                notes: 'Catatan',
                stock_out: 'Stok Habis',
                back: 'Lanjut Belanja'
            }
        },
        summary: {
            title: 'Ringkasan Pesanan',
            coupon: 'Kode Kupon',
            shop_total: 'Total Belanja',
            ship_cost: 'Biaya Pengiriman',
            insurance: 'Biaya Asuransi',
            discount: 'Diskon',
            pack: 'Biaya Packing',
            gov_tax: 'Pajak Pemerintah',
            payment_fee: 'Biaya Pembayaran',
            grand: 'Total Bayar'
        },
        shipment: {
            title: 'Pengiriman',
            address: 'Alamat Penerima',
            page: 'Alamatku',
            empty: 'Alamat Kamu Kosong',
            empty_desc:
                'Silakan tambahkan minimal satu alamat untuk menyelesaikan pesanan',
            card: {
                type: 'Tipe Alamat',
                primary: 'Utama',
                selected: 'Dipilih',
                receipt: 'Penerima',
                country: 'Negara',
                province: 'Provinsi',
                city: 'Kota',
                address: 'Alamat',
                detail: 'Detail Alamat',
                postal: 'Kode Pos',
                cta: 'Pilih Alamat Lain'
            },
            form: {
                list: 'List Alamat',
                add: 'Tambah Alamat Baru',
                update: 'Update Alamat',
                type: 'Tipe Alamat',
                receipt: 'Nama Penerima',
                email: 'Email Penerima',
                phone: 'Nomor Telepon',
                country: 'Negara',
                province: 'Provinsi',
                city: 'Kota',
                postal: 'Kode Pos',
                address: 'Alamat',
                detail: 'Nomor Bangunan / Lantai / Unit',
                main: 'Atur sebagai Alamat Utama',
                yes: 'Ya',
                no: 'Tidak',
                cta: 'Tambah Alamat',
                cta2: 'Update Alamat',
                notice_email:
                    'Informasi apapun mengenai pengiriman dan pengantaran akan dikirimkan ke email ini',
                notice_phone:
                    'Input menggunakan format internasional, contoh 628123456789'
            },
            expedition: {
                title: 'Pilih Ekspedisi',
                sent: 'Dikirim Dari',
                destination: 'Tujuan ke',
                estimate: 'Estimasi',
                days: 'Hari'
            },
            method: 'Metode Pembayaran',
            notes: 'Catatan Pengiriman',
            notes_placeholder: 'Tulis catatan pengiriman kamu disini...',
            cta: 'Bayar Sekarang',
            insurance: {
                title: 'Asuransi Pengiriman',
                active: 'Aktifkan Asuransi',
                fee: 'Biaya Asuransi',
                desc: 'Salah satu produk yang kamu beli membutuhkan asuransi, untuk pengiriman yang lebih aman sebesar',
                yes: 'Ya',
                no: 'Tidak'
            }
        },
        payment: {
            guide: 'Panduan Pembayaran',
            title: 'Pembayaran',
            close: 'Ditutup dalam',
            invoice: 'Cek Invoice',
            days: 'Hari',
            hour: 'j',
            minute: 'm',
            second: 'd',
            expirate: 'Pembayaran Kadaluarsa',
            notice: 'Terima kasih telah melakukan pemesanan. Kami mengingatkan Anda untuk menyelesaikan pembayaran pesanan Anda sebelum batas waktu yang akan datang. Jangan lupa untuk mengunggah Bukti Pembayaran pada kolom di bawah.',
            upload: 'Unggah Bukti Pembayaran',
            uploaded: 'Bukti Pembayaran Kamu',
            total: 'Total Pembayaran'
        },
        status: {
            expirate: 'Waktu Pembayaran Kamu Habis',
            expirate_desc:
                'Waktu pembayaran kamu telah habis, silakan melakukan pemesanan baru',
            expirate_cta: 'Pesan Baru',
            reject: 'Pembayaran Kamu Tidak Valid',
            reject_desc: 'Silakan unggah bukti pembayaran baru',
            reject_cta: 'Unggah Bukti Pembayaran',
            pending: 'Pembayaran Kamu Sedang Diproses',
            pending_desc:
                'Silakan tunggu sebentar tim kami akan memeriksa pembayaran kamu',
            pending_cta: 'Beli Produk Lain',
            paid: 'Selamat. Pembayaran Kamu Diterima',
            paid_desc:
                'Terima kasih telah memesan di XiaokenShop. Kami akan memproses pesanan kamu secepat mungkin.',
            paid_cta: 'Lacak Pesanan'
        },
        order: {
            title: 'Riwayat Pesanan',
            search: 'Cari Pesanan',
            empty: 'Daftar Pesanan Kamu Kosong',
            empty_desc: 'Silakan Tambahkan Pesanan Baru!',
            cta: 'Tambah Produk',
            status: {
                all: 'Semua',
                process: 'Diproses',
                success: 'Selesai',
                cancel: 'Dibatalkan',
                failed: 'Gagal',
                reset: 'Reset Filter'
            },
            card: {
                price: 'Harga',
                shipping: 'Dikirim ke',
                detail: 'Detail Transaksi',
                total: 'Total Pembayaran',
                product: 'Total Produk',
                cancel: 'Batalkan Pesanan',
                help: 'Bantuan Pesanan',
                pay: 'Bayar Sekarang',
                again: 'Pesan Lagi'
            },
            detail: {
                title: 'Detail Transaksi',
                viewmore: 'Lihat Lebih',
                number: 'Nomor Pesanan',
                date: 'Tanggal Pesanan',
                order: 'Detail Produk',
                shipping: 'Detail Pengiriman',
                expedition: 'Ekspedisi',
                address: 'Alamat Pengiriman',
                payment: 'Detail Pembayaran',
                method: 'Metode Pembayaran',
                total: 'Total Belanja',
                shipping_cost: 'Biaya Pengiriman',
                gov: 'Pajak Pemerintah',
                payment_fee: 'Biaya Pembayaran',
                grand: 'Total Pembayaran',
                discount: 'Diskon'
            }
        },
        wishlist: {
            title: 'Favoritku',
            empty: 'Favorit Kamu Kosong',
            empty_desc: 'Jelajahi produk kami dan temukan produk favorit kamu!'
        },
        profile: {
            title: 'Ubah Profil',
            verify: 'Verifikasi akun',
            email_not: 'Email Belum Terverifikasi',
            email_yes: 'Akun Terverifikasi',
            first: 'Nama Depan',
            last: 'Nama Belakang',
            dob: 'Tanggal Lahir',
            phone: 'Nomor Telepon',
            password: 'Kata Sandi',
            confirm: 'Konfirmasi Kata Sandi',
            cta: 'Update Profil'
        },
        reset: {
            title: 'Reset Kata Sandi',
            desc: 'Silakan isi kolom di bawah ini untuk mereset kata sandi kamu',
            new: 'Kata Sandi Baru',
            confirm: 'Konfirmasi Kata Sandi Baru',
            cta: 'Ubah Kata Sandi'
        },
        forgot: {
            title: 'Lupa Kata Sandi?',
            desc: 'Masukkan alamat email Anda dan kami akan mengirimkan Anda tautan untuk mereset kata sandi Anda.'
        },
        footer: {
            address: 'Alamat',
            shopping: {
                head: 'Belanja',
                home: 'Laman Utama',
                request: 'Pesanan Custom',
                all_product: 'Semua Produk',
                discount: 'Produk Diskon'
            },
            about: {
                head: 'Tentang Kami',
                about: 'Tentang',
                news: 'Berita & Event',
                tnc: 'Syarat & Ketentuan',
                privacy: 'Kebijakan Privasi'
            },
            support: {
                head: 'Bantuan',
                shop_guide: 'Panduan Belanja',
                faq: 'FAQ',
                help: 'Hubungi Kami'
            },
            store: 'Toko Kami',
            payment: 'Pembayaran',
            follow: 'Ikuti Kami'
        }
    }
}
