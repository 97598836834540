export const formatCurrency = (value: any, currency: string): string => {
    return new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency
    }).format(value)
}

export const imageUrl = (image: string) => {
    if(!image) return ''
    if (image.includes('blob:')) return image
    if (image.includes('http')) return image
    return `${window.location.origin}/storage/avatar/${image}`
}

export const convertCost = (cost: any): string => {
    return (cost / 14000).toFixed(2).toString()
}

export const displayDiscount = (price: number, discount: number): string => {
    return (((price - discount) / price) * 100).toFixed(0)
}

export const isNew = (date: string) => {
    const today = new Date()
    const dateToCheck = new Date(date)
    const threeDaysAgo = new Date(today)
    threeDaysAgo.setDate(today.getDate() - 3)
    return dateToCheck >= threeDaysAgo && dateToCheck <= today
}

export const formatDate = (date: string) => {
    const dateToCheck = new Date(date)
    return dateToCheck.toLocaleDateString('id-ID', {
        day: 'numeric',
        month: 'long',
        year: 'numeric'
    })
}

export const formatDate2 = (inputDate: string) => {
    const date = new Date(inputDate)

    const day = date.getDate()
    const monthIndex = date.getMonth()
    const year = date.getFullYear()
    const hours = date.getHours()
    const minutes = date.getMinutes()

    const monthNames = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
    ]

    const formattedDate = `${day} ${monthNames[monthIndex]} ${year}, ${hours
        .toString()
        .padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`

    return formattedDate
}
