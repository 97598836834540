<template>
    <router-link class="p-2 hover:bg-slate-100 whitespace-nowrap" v-for="item in character_data?.data"
        :to="{ name: 'Search', query: { characters: item.slug } }">
        {{ item.name }}
    </router-link>
</template>

<script setup lang="ts">
import { useSearch } from "@/Store/Search";
import { storeToRefs } from "pinia";
const { character_data } = storeToRefs(useSearch());
await useSearch().getCharacterFilter();
</script>
