<template>
    <div class="flex flex-row">
        <slot></slot>
        <span class=" animate__slow animate__infinite animate__flash animation-delay-150">.</span>
        <span class=" animate__slow animate__infinite animate__flash animation-delay-300">.</span>
    </div>
</template>

<script setup lang="ts">

</script>