<template>
    <div>
        <div
            class="flex flex-row items-center justify-start gap-2 px-3 py-2 hover:bg-slate-100 w-full"
        >
            <slot></slot>
            <!-- Parent -->
            <router-link
                :to="{ name: 'Search', query: { categories: allSlugs } }"
                class="block w-full whitespace-nowrap"
                >{{ name }}
            </router-link>
        </div>
        <!-- Child menu -->
        <template v-if="children && children.length > 0">
            <template v-for="child in children" :key="child.slug">
                <NavChildLoop
                    class="pl-2"
                    :children="child.children"
                    :name="child.name"
                    :slug="child.slug"
                >
                    <CornerDownRight class="shrink-0" :size="14" />
                </NavChildLoop>
            </template>
        </template>
    </div>
</template>

<script setup lang="ts">
import { CornerDownRight } from 'lucide-vue-next'

interface ListItem {
    name: string
    slug: string
    children: ListItem[]
}

const props = defineProps({
    name: String,
    slug: String,
    children: {
        type: Array as () => ListItem[],
        default: () => []
    }
})

const collectSlugs = (slug: string, children: ListItem[]): string[] => {
    let allSlugs = [slug]
    children.forEach((child) => {
        allSlugs.push(...collectSlugs(child.slug, child.children))
    })
    return allSlugs
}

const allSlugs = collectSlugs(props.slug || '', props.children).join('+')
</script>

<style scoped></style>
