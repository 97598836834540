import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
        name: 'Home',
        path: '/',
        component: () => import('./Pages/Home.vue'),
        meta: { middleware: 'guest', layout: 'DefaultLayout' }
    },
    {
        name: 'Request',
        path: '/request-order',
        component: () => import('./Pages/Request.vue'),
        meta: { auth: true, middleware: 'guest', layout: 'DefaultLayout' }
    },
    {
        name: 'Login',
        path: '/login',
        component: () => import('./Pages/Login.vue'),
        meta: { middleware: 'guest', layout: 'DefaultLayout' }
    },
    {
        name: 'Forgot',
        path: '/forgot-password',
        component: () => import('./Pages/Forgot.vue'),
        meta: { middleware: 'guest', layout: 'DefaultLayout' }
    },
    {
        name: 'ChangePassword',
        path: '/change-password/:token',
        component: () => import('./Pages/Change.vue'),
        meta: { middleware: 'guest', layout: 'DefaultLayout' }
    },
    {
        name: 'Register',
        path: '/register',
        component: () => import('./Pages/Register.vue'),
        meta: { middleware: 'guest', layout: 'DefaultLayout' }
    },
    {
        name: 'AllProduct',
        path: '/product',
        component: () => import('./Pages/Search.vue'),
        meta: { middleware: 'guest', layout: 'AllProductLayout' }
    },
    {
        name: 'Search',
        path: '/search',
        component: () => import('./Pages/Search.vue'),
        meta: { middleware: 'guest', layout: 'AllProductLayout' }
    },
    {
        name: 'Category',
        path: '/category/:slug',
        component: () => import('./Pages/Search.vue'),
        meta: { middleware: 'guest', layout: 'DefaultLayout' }
    },
    {
        name: 'Product',
        path: '/product/:slug',
        component: () => import('./Pages/Product.vue'),
        meta: {
            middleware: 'guest',
            layout: 'DetailProductLayout',
            matureCheck: true
        }
    },
    {
        name: 'Collection',
        path: '/collection/:slug',
        component: () => import('./Pages/Collection.vue'),
        meta: { middleware: 'guest', layout: 'DefaultLayout' }
    },
    {
        name: 'Cart',
        path: '/cart',
        component: () => import('./Pages/Cart.vue'),
        meta: { auth: true, middleware: 'guest', layout: 'CartLayout' }
    },
    {
        name: 'Shipment',
        path: '/shipment',
        component: () => import('./Pages/Shipment.vue'),
        meta: { auth: true, middleware: 'guest', layout: 'ShipmentLayout' }
    },
    {
        name: 'Payment',
        path: '/payment/:slug',
        component: () => import('./Pages/Payment.vue'),
        meta: { auth: true, middleware: 'guest', layout: 'DefaultLayout' }
    },
    {
        name: 'Status',
        path: '/status/:slug',
        component: () => import('./Pages/Status.vue'),
        meta: { auth: true, middleware: 'guest', layout: 'DefaultLayout' }
    },
    {
        name: 'RequestList',
        path: '/account/request-list',
        component: () => import('./Pages/CustomOrder.vue'),
        meta: { auth: true, middleware: 'guest', layout: 'DefaultLayout' }
    },
    {
        name: 'ListOrder',
        path: '/account/order-list',
        component: () => import('./Pages/Order.vue'),
        meta: { auth: true, middleware: 'guest', layout: 'DefaultLayout' }
    },
    {
        name: 'Wishlist',
        path: '/account/wishlist',
        component: () => import('./Pages/Wishlist.vue'),
        meta: { auth: true, middleware: 'guest', layout: 'DefaultLayout' }
    },
    {
        name: 'Profile',
        path: '/account/profile',
        component: () => import('./Pages/Profile.vue'),
        meta: { auth: true, middleware: 'guest', layout: 'DefaultLayout' }
    },
    {
        name: 'Address',
        path: '/account/address',
        component: () => import('./Pages/Address.vue'),
        meta: { auth: true, middleware: 'guest', layout: 'DefaultLayout' }
    },
    {
        name: 'Invoice',
        path: '/account/invoice/:uuid',
        component: () => import('./Pages/Invoice.vue'),
        meta: { auth: true, layout: 'InvoiceLayout' }
    },
    {
        name: 'NewsList',
        path: '/news-events',
        component: () => import('./Pages/NewsList.vue'),
        meta: { middleware: 'guest', layout: 'DefaultLayout' }
    },
    {
        name: 'NewsDetail',
        path: '/news-events/:slug',
        component: () => import('./Pages/NewsDetail.vue'),
        meta: { middleware: 'guest', layout: 'DefaultLayout' }
    },
    {
        name: 'PageDetail',
        path: '/page/:slug',
        component: () => import('./Pages/PageDetail.vue'),
        meta: { middleware: 'guest', layout: 'DefaultLayout' }
    },
    {
        name: 'OAuthCallback',
        path: '/oauth/:provider/callback',
        component: () => import('./Pages/OAuthCallback.vue'),
        meta: { middleware: 'guest', layout: 'DefaultLayout' }
    },
    {
        name: 'Error404',
        path: '/:pathMatch(.*)*',
        component: () => import('./Pages/Error404.vue'),
        meta: { middleware: 'guest', layout: 'DefaultLayout' }
    }
]

export default createRouter({
    history: createWebHistory(),
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { top: 0 }
        }
    },
    routes
})
