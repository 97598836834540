<template>
    <div class="w-full bg-slate-100 rounded-lg  relative overflow-hidden">
        <div class="w-full h-full animate__animated animate__delay-1s animate__infinite animate__fadeOut">
            <div
                class="bg-gradient-to-l from-slate-300 to-transparent content-[''] w-full h-full animate__slow animate__fadeInLeftBig animate__infinite">
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">

</script>