<template>
    <nav
        class="fixed sm:hidden bg-white bottom-0 z-20 w-full shadow-[0_-5px_15px_0_rgba(0,0,0,0.2)] flex flex-row items-center justify-center text-xs py-2 text-slate-400">
        <MenuBottom url="/" :name="$t('navbar.home')">
            <Home :size="18" :stroke-width="2.5" class="mb-.5" />
        </MenuBottom>
        <template v-if="!loggedIn">
            <MenuBottom url="/product" :name="$t('navbar.product')">
                <Blocks :size="18" :stroke-width="2.5" class="mb-.5" />
            </MenuBottom>
            <MenuBottom url="/request-order" :name="$t('navbar.dropdown.request')">
                <ScrollText :size="18" :stroke-width="2.5" class="mb-.5" />
            </MenuBottom>
            <MenuBottom url="/login" :name="$t('navbar.login')">
                <LogIn :size="18" :stroke-width="2.5" class="mb-.5" />
            </MenuBottom>
        </template>
        <template v-else>
            <MenuBottom url="/product" :name="$t('navbar.product')">
                <Blocks :size="18" :stroke-width="2.5" class="mb-.5" />
            </MenuBottom>
            <MenuBottom url="/cart" :name="$t('navbar.cart')">
                <ShoppingCart :size="18" :stroke-width="2.5" class="mb-.5" />
            </MenuBottom>
            <MenuBottom url="/account/order-list" :name="$t('navbar.dropdown.list_order')">
                <LayoutList :size="18" :stroke-width="2.5" class="mb-.5" />
            </MenuBottom>
        </template>
    </nav>
</template>

<script setup lang="ts">
import { Blocks, Home, LayoutList, LogIn, ScrollText, ShoppingCart } from "lucide-vue-next";
import MenuBottom from "./MenuBottom.vue";
//@ts-ignore
import { useAuth } from "vue3-auth";
const { loggedIn } = useAuth()
</script>
