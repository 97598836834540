<template>
    <RouterLink :to="{ name: 'Wishlist' }"
        class="hidden py-2 max-md:flex px-3 gap-3 items-center justify-start group/menu hover:bg-slate-100">
        <ShoppingCart :size="16" class="text-slate-300 group-hover/menu:text-theme1 transition-all" />
        {{ $t('navbar.dropdown.cart') }}
    </RouterLink>
    <router-link :to="{ name: 'ListOrder' }"
        class="py-2 flex px-3 gap-3 items-center justify-start group/menu hover:bg-slate-100">
        <Package2 :size="16" class="text-slate-300 group-hover/menu:text-theme1 transition-all" />
        {{ $t('navbar.dropdown.list_order') }} <div v-if="totalOrderStatus.data > 0"
            class="scale-90 text-xs ml-auto px-2 h-5 flex items-center justify-center bg-yellow-300 text-orange-800 rounded-full">
            {{ totalOrderStatus.data }}</div>
    </router-link>
    <RouterLink :to="{ name: 'Wishlist' }"
        class="py-2 flex px-3 gap-3 items-center justify-start group/menu hover:bg-slate-100">
        <Heart :size="16" class="text-slate-300 group-hover/menu:text-theme1 transition-all" />
        {{ $t('navbar.dropdown.wishlist') }}
    </RouterLink>
    <RouterLink :to="{ name: 'Profile' }"
        class="py-2 flex px-3 gap-3 items-center justify-start group/menu  hover:bg-slate-100">
        <UserCog :size="16" class="text-slate-300 group-hover/menu:text-theme1 transition-all" />
        {{ $t('navbar.dropdown.profile') }}
    </RouterLink>
    <RouterLink :to="{ name: 'Address' }"
        class="py-2 flex px-3 gap-3 items-center justify-start group/menu  hover:bg-slate-100">
        <MapPinned :size="16" class="text-slate-300 group-hover/menu:text-theme1 transition-all" />
        {{ $t('navbar.dropdown.address') }}
    </RouterLink>
    <button @click="logoutOut" class="py-2 flex px-3 gap-3 items-center justify-start group/menu hover:bg-slate-100">
        <LogOut :size="16" class="text-slate-300 group-hover/menu:text-theme1 transition-all" />
        {{ $t('navbar.dropdown.logout') }}
    </button>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
const { t, locale } = useI18n({ useScope: 'global' })
import { Heart, LogOut, Package2, UserCog, MapPinned, ShoppingCart } from "lucide-vue-next";
import { ref } from "vue";
//@ts-ignore
import { useAuth } from "vue3-auth";
const { logout } = useAuth()

const logoutOut = () => {
    logout();
    window.location.reload();
};

const totalOrderStatus = ref<any>(0);


const totalOrderUnpaid = async () => {
    try {
        const { data } = await window.axios.post(`/api/order/filter/status`);
        totalOrderStatus.value = data;
    } catch (error) {
        console.log(error);
    }
};

await totalOrderUnpaid();
</script>
