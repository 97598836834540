<template>
    <nav :class="{ focus: isFocused }" class="sticky bg-white top-0 z-50 w-full shadow-lg
        group/main max-sm:[&.focus]:fixed max-sm:[&.focus]:h-screen max-sm:[&.focus]:bg-white transition-all duration-500 max-sm:overflow-hidden font-medium
        ">
        <div
            class="w-full flex text-sm flex-row flex-wrap gap-2 px-4 py-2 items-center justify-between bg-gradient-to-l from-theme1 to-theme2 sm:hidden">
            <div class="flex flex-row flex-wrap items-center justify-center gap-2 text-white">
                {{ $t("navbar.lang") }}:
                <div @click="setLocale('en')" :class="{ active: locale == 'en' }"
                    class="bg-white bg-opacity-20 text-white [&.active]:bg-opacity-100 [&.active]:text-slate-600 px-2 rounded">
                    English
                </div>
                <div @click="setLocale('id')" :class="{ active: locale == 'id' }"
                    class="bg-white bg-opacity-20 text-white [&.active]:bg-opacity-100 [&.active]:text-slate-600 px-2 rounded">
                    Indonesia
                </div>
            </div>
            <a target="_blank" :href="`https://wa.me/${setting_data?.website_whatsapp}?text=Hello Xiaoken!`"
                class="text-white flex flex-row gap-2 items-center jusc">
                <MessagesSquare :size="18" />
                Contact Us
            </a>
        </div>
        <div
            class="px-6 max-sm:group-[.focus]/main:px-3 transition-all duration-500 py-3 gap-3 sm:gap-6 flex flex-row sm:items-center justify-between h-full">
            <router-link to="/" class="max-sm:hidden">
                <img class="w-auto h-12" src="/logo.png" alt="Logo-Website">
            </router-link>
            <!--  -->
            <div class="hidden lg:flex flex-row items-center justify-center gap-2">
                <!-- <router-link class="text-gray-600 text-sm hover:bg-slate-100 transition-all duration-500 p-2 rounded-md"
                    to="/">
                    {{ $t("navbar.home") }}
                </router-link> -->
                <div class="group/category cursor-pointer text-gray-600 text-sm flex gap-1 hover:bg-slate-100 transition-all duration-500 p-2 rounded-md relative"
                    href="#">{{ $t("navbar.series") }}
                    <ChevronDown class="mt-0.5 group-hover/category:rotate-180 transition-all duration-500"
                        :size="18" />

                    <div
                        class="absolute left-0 z-10 min-w-[140px] group-hover/category:translate-y-2 group-hover/category:border rounded-md shadow-lg top-full bg-white flex flex-col max-h-0 group-hover/category:max-h-[200px] overflow-x-hidden overflow-y-auto scrollbar-thin scrollbar-track-slate-50 scrollbar-thumb-slate-200 transition-all duration-500">

                        <!-- Content -->
                        <Suspense>
                            <DropdownSeries />
                            <template #fallback>
                                loading...
                            </template>
                        </Suspense>
                    </div>
                </div>
                <div class="group/category cursor-pointer text-gray-600 text-sm flex gap-1 hover:bg-slate-100 transition-all duration-500 p-2 rounded-md relative"
                    href="#">{{ $t("navbar.character") }}
                    <ChevronDown class="mt-0.5 group-hover/category:rotate-180 transition-all duration-500"
                        :size="18" />

                    <div
                        class="absolute left-0 z-10 min-w-[140px] group-hover/category:translate-y-2 group-hover/category:border rounded-md shadow-lg top-full bg-white flex flex-col max-h-0 group-hover/category:max-h-[200px] overflow-x-hidden overflow-y-auto scrollbar-thin scrollbar-track-slate-50 scrollbar-thumb-slate-200 transition-all duration-500">

                        <!-- Content -->
                        <Suspense>
                            <DropdownCharacter />
                            <template #fallback>
                                loading...
                            </template>
                        </Suspense>
                    </div>
                </div>
            </div>
            <!-- Search bar -->
            <SearchBar @focus="(val) => isFocused = val" />

            <div class="max-sm:group-[.focus]/main:hidden flex flex-row items-start justify-center gap-3">
                <template v-if="loggedIn">
                    <div @click="openMobile = true"
                        class="w-9 h-9 hidden bg-violet-600 rounded-full overflow-hidden max-sm:flex items-center justify-center text-white font-bold text-xs">
                        <img v-if="user.avatar" class="w-full h-full object-cover" :src="imageUrl(user.avatar)"
                            :alt="user.first_name">
                        <User v-else :size="20" />
                    </div>
                    <div
                        class="max-sm:hidden rounded-full pl-1 pr-2 py-1 flex flex-row items-center justify-center gap-1 lg:gap-3 text-gray-600 text-sm shadow relative group/user sm:hover:shadow-xl transition-all duration-500 sm:hover:border-theme1 border border-transparent">
                        <div
                            class="w-9 h-9 md:w-8 md:h-8 bg-violet-600 rounded-full overflow-hidden flex items-center justify-center text-white font-bold text-xs">
                            <img v-if="user.avatar" class="w-full h-full object-cover" :src="imageUrl(user.avatar)"
                                :alt="user.first_name">
                            <User v-else :size="20" />
                        </div>
                        <span class=" capitalize">{{ user.first_name }}</span>
                        <ChevronDown class="max-sm:hidden mt-1" :size="18" />

                        <!-- Dropdown -->
                        <div
                            class="max-sm:hidden absolute max-md:right-0 md:left-0 z-10 min-w-[200px] w-full group-hover/user:translate-y-2 group-hover/user:border rounded-xl shadow-lg top-full bg-white text-slate-600 font-normal flex flex-col max-h-0 group-hover/user:max-h-[320px] overflow-hidden transition-all duration-500">
                            <Suspense>
                                <UserDropdown />
                                <template #fallback>
                                    loading...
                                </template>
                            </Suspense>
                        </div>
                    </div>
                    <router-link :to="{ name: 'Cart' }" class="relative block group max-md:hidden ">
                        <div
                            class="rounded-full px-4 py-2 bg-gradient-to-l from-theme1 to-theme2 group-hover:shadow-xl group-hover:brightness-125 transition-all duration-500 shadow-md cursor-pointer text-sm text-white font-medium flex flex-row items-center justify-center gap-3">
                            <ShoppingCart :size="20" /> {{ $t("navbar.cart")
                            }}

                            ({{ cart_data?.length ? cart_data?.length : 0 }})
                        </div>

                        <!-- Content -->
                        <div class="absolute right-0 z-10 min-w-[320px] max-w-md rounded-xl shadow-lg top-full bg-white  flex flex-col
                        overflow-hidden transition-all duration-500 max-h-0 delay-200
                        group-hover:max-h-[240px] group-hover:translate-y-2 group-hover:border
                    ">
                            <div class="p-3 flex flex-row items-center justify-between">
                                <div class="text-lg font-bold ">{{ $t('navbar.dropdown.cart') }}({{ cart_data?.length
                                    }})
                                </div>
                                <div class="text-theme1 text-sm font-medium">{{ $t('navbar.dropdown.cart_detail') }}
                                </div>
                            </div>
                            <div
                                class="scrollbar-thin scrollbar-track-slate-50 scrollbar-thumb-slate-200 h-full flex flex-col overflow-y-auto overflow-x-hidden py-2">
                                <div v-if="cart_data?.length === 0">
                                    <div class="p-4 text-center text-slate-400">
                                        <CircleOff class="mx-auto" :size="56" />
                                        <h1 class="text-lg capitalize">{{ $t('navbar.empty.title') }}</h1>
                                        <div class="text-sm font-normal text-slate-400 mt-2">
                                            {{ $t('navbar.empty.desc') }}</div>
                                    </div>
                                </div>
                                <div v-for="(item, index) in cart_data" :key="index"
                                    class="hover:bg-slate-100 flex flex-row items-center justify-between px-3 py-2 border-b">
                                    <div class="flex flex-row items-center justify-start gap-3">
                                        <img class="w-10 h-10 rounded-md object-cover" :src="item.product_thumbnail"
                                            :alt="item.product_name">
                                        <div class="flex flex-col ">
                                            <span class="text-sm line-clamp-1">{{ item.product_name }}</span>
                                            <span class="text-xs font-normal text-slate-400">{{ item.combination_name }}
                                                ({{ item.weight }}gram)</span>
                                        </div>
                                    </div>
                                    <div class="flex flex-col items-end justify-end gap-1">
                                        <span class="text-xs truncate text-right text-slate-400 w-20">{{
        item.discount ?
            formatCurrency(item.discount, currentCurrency) :
            formatCurrency(item.price, currentCurrency) }}</span>
                                        <span class="text-xs font-normal text-slate-400">x {{ item.quantity }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </template>
                <template v-else>
                    <router-link :to="{ name: 'Login' }"
                        class="max-sm:hidden md:rounded-full md:px-8 md:py-2 text-gray-600 text-sm md:shadow  hover:shadow-xl transition-all duration-500 border-transparent">
                        <span>{{ $t("navbar.login") }}</span>
                    </router-link>

                    <router-link :to="{ name: 'Register' }" class="relative group max-md:hidden ">
                        <div
                            class="rounded-full px-8 py-2 bg-gradient-to-l from-theme1 to-theme2   group-hover:shadow-xl group-hover:brightness-125 transition-all duration-500 shadow-md cursor-pointer text-sm text-white font-medium">
                            {{ $t("navbar.register") }}
                        </div>
                    </router-link>
                </template>
            </div>

        </div>
        <Suspense>
            <NavCategory />
            <template #fallback>
                <div
                    class="hidden h-1 w-full relative bg-gradient-to-l from-theme1 to-theme2 content-[''] sm:flex items-center justify-between">
                </div>
            </template>
        </Suspense>
    </nav>

    <div v-if="prefLangRef" @click="prefLangRef = false"
        class="w-full h-full fixed top-0 left-0 z-40 flex flex-row items-center justify-center">
        <div class="bg-white max-w-md w-full z-10 p-8 pt-2 shadow-xl">
            <h1 class="text-xl font-medium text-theme1 text-center py-4 border-b mb-4">Choose Your Prefered Languange!!
            </h1>

            <div class="flex flex-row gap-4 items-center justify-center">
                <div @click="setLocale('en')"
                    class="w-full p-4 border shadow hover:shadow-lg hover:bg-theme1 hover:text-white transition-all duration-500 cursor-pointer flex items-center justify-center flex-row">
                    <Globe class="mr-1" :stroke-width="1" /> EN - English
                </div>
                <div @click="setLocale('id')"
                    class="w-full p-4 border shadow hover:shadow-lg hover:bg-theme1 hover:text-white transition-all duration-500 cursor-pointer flex items-center justify-center flex-row">
                    <Globe class="mr-1" :stroke-width="1" /> ID - Indonesia
                </div>
            </div>
        </div>
        <div class="absolute top-0 left-0 w-full h-full bg-black/75 backdrop-blur-sm"></div>
    </div>

    <label
        class="fixed bottom-3 left-3 bg-gradient-to-l from-theme1 to-theme2 hidden sm:flex gap-2 p-1.5 rounded-full z-50 shadow-lg cursor-pointer">
        <input type="checkbox" class="peer hidden" v-model="isLocaleId">

        <div
            class="w-7 h-7 transition-all duration-500 bg-white text-violet-500 peer-checked:bg-transparent peer-checked:text-white rounded-3xl shadow justify-center items-center flex">
            <div class=" text-xs font-bold leading-none">EN</div>
        </div>
        <div
            class="w-7 h-7 transition-all duration-500 text-white peer-checked:bg-white peer-checked:text-violet-500  rounded-3xl shadow justify-center items-center flex">
            <div class="text-xs font-bold leading-none">ID</div>
        </div>
    </label>

    <OpenMobile v-if="loggedIn" :openMobile="openMobile" @updateOpenMobile="openMobile = $event" />

    <MobileMenu />
</template>

<script setup>
import SearchBar from '@/Components/Search/SearchBar.vue';
import { useCart } from '@/Store/Cart';
import { useLocales } from '@/Store/Locale';
import { formatCurrency, imageUrl } from '@/helpers';
import { ChevronDown, CircleOff, Globe, MessagesSquare, ShoppingCart, User } from "lucide-vue-next";
import { storeToRefs } from "pinia";
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useAuth } from "vue3-auth";
import DropdownCharacter from './Navbar/DropdownCharacter.vue';
import DropdownSeries from './Navbar/DropdownSeries.vue';
import MobileMenu from './Navbar/MobileMenu.vue';
import NavCategory from './Navbar/NavCategory.vue';
import OpenMobile from './Navbar/OpenMobile.vue';
import UserDropdown from './Navbar/UserDropdown.vue';

const prefLangRef = ref(false);

import VueCookies from 'vue-cookies';
const cookies = VueCookies;

const checkPreferredLang = () => {
    const preferredLang = cookies.get('prefered-lang');

    if (!preferredLang) {
        prefLangRef.value = true;
        cookies.set('prefered-lang', true);
    }
};

checkPreferredLang();

import { useHome } from '@/Store/Home';
const { setting_data } = storeToRefs(useHome());

const { user, loggedIn } = useAuth()
const storeCart = useCart();
const { cart_data } = storeToRefs(storeCart);
const idUser = user.value?.id;

if (idUser) {
    storeCart.getCart();
}

const openMobile = ref(false);

const isFocused = ref(false);

const { t, locale } = useI18n({ useScope: 'global' })

const { setLang } = useLocales();

const currentCurrency = computed(() => {
    switch (locale.value) {
        case 'en':
            return 'USD';
        case 'id':
            return 'IDR';
        default:
            return 'USD';
    }
});

const isLocaleId = computed({
    get: () => locale.value === 'id',
    set: (value) => {
        const newLocale = value ? 'id' : 'en';
        setLang(newLocale);
        locale.value = newLocale;
        window.location.reload();
    }

});

const setLocale = (lang) => {
    setLang(lang);
    locale.value = lang;
    window.location.reload();
};
</script>
