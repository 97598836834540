<template>
    <div class="justify-start items-start gap-3 flex flex-col flex-wrap">
        <div class="text-white text-lg font-medium">{{ $t('footer.follow') }}:</div>
        <div class="justify-start items-center gap-3.5 flex flex-wrap">
            <a :href="setting_data?.website_facebook" target="_blank"
                class="w-8 h-8 px-3 py-1.5 bg-white rounded-3xl border flex-col justify-center items-center gap-2.5 inline-flex">
                <Facebook />
            </a>
            <a :href="setting_data?.website_twitter" target="_blank"
                class="w-8 h-8 px-3 py-1.5 bg-white rounded-3xl border flex-col justify-center items-center gap-2.5 inline-flex">
                <X />
            </a>
            <a :href="setting_data?.website_instagram" target="_blank"
                class="w-8 h-8 px-3 py-1.5 bg-white rounded-3xl border flex-col justify-center items-center gap-2.5 inline-flex">
                <Instagram />
            </a>
            <a :href="setting_data?.website_youtube" target="_blank"
                class="w-8 h-8 px-3 py-1.5 bg-white rounded-3xl border flex-col justify-center items-center gap-2.5 inline-flex">
                <Youtube />
            </a>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useHome } from '@/Store/Home';
import { Facebook, Instagram, X, Youtube } from 'lucide-vue-next';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
const { locale } = useI18n({ useScope: 'global' })

const { setting_data } = storeToRefs(useHome());
</script>
