<template>
    <a target="_blank" :href="`https://wa.me/${setting_data?.website_whatsapp}?text=Hello Xiaoken!`"
        class="fixed bottom-3 right-3 bg-gradient-to-l from-theme1 to-theme2 hidden sm:flex gap-2 p-1.5 rounded-full z-50 shadow-lg hover:brightness-125 transition-all duration-500">
        <div class="px-3 h-7  text-white rounded-3xl shadow justify-center items-center flex gap-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="lucide lucide-messages-square">
                <path d="M14 9a2 2 0 0 1-2 2H6l-4 4V4c0-1.1.9-2 2-2h8a2 2 0 0 1 2 2v5Z" />
                <path d="M18 9h2a2 2 0 0 1 2 2v11l-4-4h-6a2 2 0 0 1-2-2v-1" />
            </svg>
            <div class="text-xs font-medium leading-none uppercase">{{ $t('navbar.message') }}</div>
        </div>
    </a>
    <section class="bg-slate-800 py-12">
        <div class="container grid gap-6 grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
            <div class="flex flex-col gap-4">
                <Suspense>
                    <WebData />
                    <template #fallback>
                        Loading..
                    </template>
                </Suspense>
            </div>
            <div class="md:col-span-2 flex-col justify-start items-start gap-7 inline-flex">
                <div class="grid grid-cols-2 md:grid-cols-3 gap-6 max-sm:py-6 w-full">
                    <div class="flex-col gap-3 flex text-slate-400 text-sm font-normal leading-tight">
                        <div class="text-white text-lg font-medium">{{ $t('footer.shopping.head') }}</div>
                        <RouterLink :to="{ name: 'Home' }">{{ $t('footer.shopping.home') }}</RouterLink>
                        <RouterLink :to="{ name: 'Request' }">{{ $t('footer.shopping.request') }}</RouterLink>
                        <RouterLink :to="{ name: 'AllProduct' }">{{ $t('footer.shopping.all_product') }}</RouterLink>
                        <RouterLink :to="{ name: 'AllProduct', query: { other: 'discount', sort: 'relevant' } }">{{
        $t('footer.shopping.discount') }}</RouterLink>
                    </div>
                    <div class="flex-col gap-3 flex text-slate-400 text-sm font-normal leading-tight">
                        <div class="text-white text-lg font-medium">{{ $t('footer.about.head') }}</div>
                        <RouterLink :to="{ name: 'PageDetail', params: { slug: 'about-us' } }">{{
        $t('footer.about.about') +
        ' ' + setting_data?.website_title
    }}
                        </RouterLink>
                        <RouterLink :to="{ name: 'NewsList' }">{{ $t('footer.about.news') }}</RouterLink>
                        <RouterLink :to="{ name: 'PageDetail', params: { slug: 'terms-conditions' } }">{{
        $t('footer.about.tnc') }}
                        </RouterLink>
                        <RouterLink :to="{ name: 'PageDetail', params: { slug: 'privacy-policy' } }">{{
        $t('footer.about.privacy') }}
                        </RouterLink>
                    </div>

                    <div class="flex-col gap-3 flex text-slate-400 text-sm font-normal leading-tight">
                        <div class="text-white text-lg font-medium">{{ $t('footer.support.head') }}</div>
                        <RouterLink :to="{ name: 'PageDetail', params: { slug: 'shopping-guidelines' } }">{{
        $t('footer.support.shop_guide') }}</RouterLink>
                        <RouterLink :to="{ name: 'PageDetail', params: { slug: 'faq' } }">{{ $t('footer.support.faq') }}
                        </RouterLink>
                        <!-- <div>Payment Proof</div>
                        <RouterLink :to="{ name: 'PageDetail', params: { slug: 'return-refund' } }">Return Product
                        </RouterLink> -->
                        <a target="_blank"
                            :href="`https://wa.me/${setting_data?.website_whatsapp}?text=Hello Xiaoken!`">{{
        $t('footer.support.help') }}</a>
                    </div>
                    <!-- <div class="flex-col gap-3 flex text-slate-400 text-sm font-normal leading-tight">
                        <div class="text-white text-lg font-medium">Support</div>
                        <RouterLink :to="{ name: 'PageDetail', params: { slug: 'become-partners' } }">Become Partners
                        </RouterLink>
                        <div>Contact Us</div>
                        <div>Report Bugs </div>
                        <div>Feedback</div>
                    </div> -->
                </div>

                <div class="justify-between items-center flex flex-wrap gap-6 w-full">
                    <div class="flex-col justify-center items-start gap-3 inline-flex">
                        <div class="text-white text-lg font-medium">{{ $t('footer.payment') }}</div>
                        <div class="brightness-125 self-stretch justify-start items-center gap-7 inline-flex flex-wrap">
                            <img class="w-auto h-8 brightness-125" alt="payment paypal" src="/paypal.png" />
                            <img class="w-auto h-8 brightness-125" alt="payment bca" src="/bca.png" />
                            <!-- <img class="w-auto h-10 brightness-150" alt="payment wise" src="/wise.png" /> -->
                        </div>
                    </div>

                    <div class="flex flex-col gap-3">
                        <div class="text-white text-lg font-medium">{{ $t('footer.store') }}</div>
                        <div class="flex flex-row items-center justify-center flex-wrap gap-2">
                            <a href="https://www.tokopedia.com/xiaokenshop" target="_blank"
                                class="flex hover:opacity-75 transition-all flex-row gap-1.5 items-center justify-center bg-white text-sm text-slate-900 px-2 py-1 rounded">
                                <Store :size="16" :stroke-width="2" />Tokopedia
                            </a>
                            <a href="https://xiaokenshop.booth.pm/" target="_blank"
                                class="flex hover:opacity-75 transition-all flex-row gap-1.5 items-center justify-center bg-white text-sm text-slate-900 px-2 py-1 rounded">
                                <Store :size="16" :stroke-width="2" />Booth
                            </a>
                            <a href="https://maps.app.goo.gl/rdtDVndk4sq5EZ4e9" target="_blank"
                                class="flex hover:opacity-75 transition-all flex-row gap-1.5 items-center justify-center bg-white text-sm text-slate-900 px-2 py-1 rounded">
                                <Store :size="16" :stroke-width="2" />Offline Store
                            </a>
                        </div>
                    </div>

                    <SocialMedia />
                </div>
            </div>
        </div>
    </section>

    <footer class="bg-slate-900 text-slate-400 max-sm:mb-12 relative">
        <div class="container flex flex-row items-center justify-between flex-wrap gap-3 py-6">
            <div>
                <span class=" text-sm font-medium leading-tight">
                    &copy; Copyright XiaokenShop {{ new Date().getFullYear() }} | Develop by
                </span>
                <span class=" text-sm font-medium underline leading-tight">Wonder Project</span>
            </div>

            <div>
                <div @click="setLocale('en')" :class="{ active: locale == 'en' }"
                    class="px-3 py-1.5 [&.active]:bg-slate-600 [&.active]:text-slate-200 rounded inline">
                    English
                </div>
                <div @click="setLocale('id')" :class="{ active: locale == 'id' }"
                    class="px-3 py-1.5 [&.active]:bg-slate-600 [&.active]:text-slate-200 rounded inline">
                    Indonesia
                </div>
            </div>
        </div>
    </footer>
</template>

<script setup lang="ts">
import WebData from '@/Components/Section/Footer/WebData.vue';
import { useLocales } from '@/Store/Locale';
import { useI18n } from 'vue-i18n';
import SocialMedia from './Footer/SocialMedia.vue';

import { Store } from 'lucide-vue-next';

import { useHome } from '@/Store/Home';
import { storeToRefs } from 'pinia';
const { setting_data } = storeToRefs(useHome());

const { locale } = useI18n({ useScope: 'global' })
const { setLang } = useLocales();
const setLocale = (lang: string) => {
    setLang(lang);
    locale.value = lang;
    window.location.reload();
};
</script>
