<template>
    <div class=" justify-start items-center gap-6 flex">
        <img alt="logo website" class="w-16 h-16" :src="setting_data.website_logo" />
        <div class="text-white text-3xl font-bold">
            <template v-if="isLang == 'id'">
                {{ setting_data.translatable.website_title.id }}
            </template>
            <template v-else>
                {{ setting_data.translatable.website_title.en }}
            </template>
        </div>
    </div>

    <div class="max-w-sm text-slate-400 text-sm leading-relaxed">
        <div v-if="isLang == 'id'" v-html="renderFix(setting_data.translatable.website_description.id)">
        </div>
        <div v-else v-html="renderFix(setting_data.translatable.website_description.en)">
        </div>
    </div>

    <div class=" flex-col justify-start items-start gap-3 flex text-white text-sm tracking-wide leading-tight">
        <a :href="`mailto:${setting_data.website_email}`">Mail: {{ setting_data.website_email }}</a>
        <a target="_blank" :href="`https://wa.me/${setting_data.website_whatsapp}?text=Hello Xiaoken!`">WhatsApp: {{
            setting_data.website_whatsapp
        }}</a>
        <p>Address: {{ setting_data.website_address }}</p>
    </div>
</template>

<script setup lang="ts">
import { useHome } from '@/Store/Home';
import { useLocales } from '@/Store/Locale';
import { storeToRefs } from 'pinia';

const { isLang } = storeToRefs(useLocales());

const { setting_data } = storeToRefs(useHome());

await useHome().getSetting();

const renderFix = (content: string) => {
    return content?.replace(/\n/g, "<br>");
};

</script>
