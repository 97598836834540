import { ref } from 'vue'
import { defineStore } from 'pinia'
import Swal from 'sweetalert2'
import { useCart } from './Cart'
import { useLocales } from './Locale'

export const useShipment = defineStore('useShipment', () => {
    const shipping_note = ref<string>('')
    const shipping_address = ref<any>([])
    const selected_address = ref<any>(null)
    const idAddress = ref<number>(0)
    const modalState = ref<string>('')
    const is_primary = ref<string>('')
    const address_note = ref<string>('')
    const recipient_name = ref<string>('')
    const email = ref<string>('')
    const phone = ref<string>('')
    const address = ref<string>('')
    const address_detail = ref<string>('')
    const country = ref<string>('')
    const province = ref<string>('')
    const province_id = ref<string>('')
    const city = ref<string>('')
    const city_id = ref<string>('')
    const postal_code = ref<string>('')
    const province_list = ref<any>([])
    const city_list = ref<any>([])
    const expedition_list = ref<any>([])
    const selected_expedition = ref('')

    const shipping_information = ref<any>(null)

    const getShippingInformation = async () => {
        try {
            const response = await window.axios.get(
                `/api/page/shipping?lang=${useLocales().isLang}`
            )
            shipping_information.value = response.data.data
        } catch (error) {
            console.log(error)
        }
    }

    const getAddresses = async () => {
        try {
            const response = await window.axios.get('/api/address')
            shipping_address.value = response.data.data
        } catch (error) {
            console.log(error)
        }
    }

    const getProvince = async () => {
        try {
            const response = await window.axios.get('/api/province')
            province_list.value = response.data.rajaongkir.results
        } catch (error) {
            console.log(error)
        }
    }

    const getCity = async () => {
        try {
            const response = await window.axios.get(
                `/api/city/${province_id.value}`
            )
            city_list.value = response.data.rajaongkir.results
        } catch (error) {
            console.log(error)
        }
    }

    const setAddressDefault = async (order: boolean) => {
        await getAddresses()
        const primaryAddress = shipping_address.value.find(
            (address: any) => address.is_primary === true
        )
        selected_address.value = primaryAddress ?? null

        if (order) {
            if (shipping_address.value.length > 0 && primaryAddress) {
                useCart().haveAddress = true

                // Check Address jika Indonesia
                if (selected_address.value.country == 'Indonesia') {
                    // Check City Id before get Expedition
                    if (selected_address.value.city_id == null || selected_address.value.city_id == '') {
                        form_set('update', selected_address.value.id)
                        Swal.fire({
                            icon: 'error',
                            title: 'Missing City Id',
                            text: 'Please reselect the province & city',
                            showConfirmButton: true,
                        })
                        getProvince()
                        getCity()
                        return
                    }
                    // console.log('Aktifkan Raja Ongkir')
                    await getExpedition()
                }
            } else {
                useCart().haveAddress = false
            }

            if (useLocales().isLang == 'en') {
                useCart().paymentMethod = 'paypal'
            } else {
                useCart().paymentMethod = 'bca'
            }
        }
    }

    const clearAddress = () => {
        // reset the form
        is_primary.value = 'true'
        address_note.value = ''
        recipient_name.value = ''
        email.value = ''
        phone.value = ''
        address.value = ''
        address_detail.value = ''
        country.value = ''
        province.value = ''
        province_id.value = ''
        city.value = ''
        city_id.value = ''
        postal_code.value = ''
        modalState.value = 'select'
    }

    // add a new address
    const submitAddress = async () => {
        // console.log('submitAddress')
        // Checking data validation country must have value
        if (country.value == '') {
            Swal.fire({
                icon: 'error',
                title: 'Country must be filled',
                showConfirmButton: false,
                timer: 1500
            })
            return
        }

        // if country is Indonesia, then province id and city id must have value
        if (country.value == 'Indonesia') {
            // console.log('Indonesia')
            if (province_id.value == '' || province_id.value == null) {
                Swal.fire({
                    icon: 'error',
                    title: 'Missing Id Province',
                    text: 'Please reselect the province',
                    showConfirmButton: true,
                })
                province.value = ''
                getProvince()
                return
            }

            if (city_id.value == '' || city_id.value == null) {
                Swal.fire({
                    icon: 'error',
                    title: 'Missing Id City',
                    text: 'Please reselect the province & city',
                    showConfirmButton: true,
                })
                city.value = ''
                getCity()
                return
            }
        }

        const data = {
            is_primary: is_primary.value,
            address_note: address_note.value,
            recipient_name: recipient_name.value,
            email: email.value,
            phone: phone.value,
            address: address.value,
            address_detail: address_detail.value,
            country: country.value,
            province: province.value,
            province_id: province_id.value.toString(),
            city: city.value,
            city_id: city_id.value.toString(),
            postal_code: postal_code.value
        }

        try {
            if (modalState.value == 'add') {
                const response = await window.axios.post('/api/address', data)
                shipping_address.value = response.data.data

                Swal.fire({
                    icon: 'success',
                    title: 'Address Added!!',
                    showConfirmButton: false,
                    timer: 1500
                })

                setAddressDefault(true)
                modalState.value = 'select'
            } else if (modalState.value == 'update') {
                const response = await window.axios.post(
                    `/api/address/${idAddress.value}`,
                    data
                )
                shipping_address.value = response.data.data
                setAddressDefault(true)
                modalState.value = 'select'
                Swal.fire({
                    icon: 'success',
                    title: 'Address Updated!!',
                    showConfirmButton: false,
                    timer: 1500
                })
            }

            // modalState.value = ''
            getAddresses()
            clearAddress()
        } catch (error: any) {
            console.error('An error occurred:', error)
            Swal.fire({
                icon: 'error',
                title: "Can't add address",
                text: error.response.data.message,
                showConfirmButton: false,
                timer: 2000
            })
        }
    }

    const getExpedition = async () => {
        await useCart().getCart()

        const data = {
            destination: selected_address.value.city_id,
            weight: await useCart().totalWeight
        }

        try {
            const response = await window.axios.post(`/api/expedition`, data)
            expedition_list.value = response.data.rajaongkir
        } catch (error) {
            console.log(error)
        }
    }

    const updateSelectedAddress = async (addressId: number) => {
        const selectedAddress = shipping_address.value.find(
            (address: any) => address.id === addressId
        )
        if (selectedAddress) {
            selected_address.value = selectedAddress
            if (selected_address.value.country == 'Indonesia') {
                // console.log('Aktifkan Raja Ongkir')
                if (selected_address.value.city_id == null || selected_address.value.city_id == '') {
                    form_set('update', selected_address.value.id)
                    Swal.fire({
                        icon: 'error',
                        title: 'Missing City Id',
                        text: 'Please reselect the province & city',
                        showConfirmButton: true,
                    })
                    getProvince()
                    getCity()
                    return
                }

                await getExpedition()
            }
        }
        useCart().haveAddress = true
    }

    const updatePrimaryAddress = async (addressId: number) => {
        try {
            const response = await window.axios.put(
                `/api/address/${addressId}/primary`
            )
            getAddresses()
        } catch (error: any) {
            console.error('An error occurred:', error)
            Swal.fire({
                icon: 'error',
                title: "Can't Set Primary Address",
                text: error.response.data.message,
                showConfirmButton: false,
                timer: 2000
            })
        }
    }

    const form_set = async (type: string, id: any) => {
        if (type == 'update') {
            try {
                const response = await window.axios.get(`/api/address/${id}`)
                is_primary.value = response.data.data.is_primary.toString()
                address_note.value = response.data.data.address_note
                recipient_name.value = response.data.data.recipient_name
                email.value = response.data.data.email
                phone.value = response.data.data.phone
                address.value = response.data.data.address
                address_detail.value = response.data.data.address_detail
                country.value = response.data.data.country
                province.value = response.data.data.province
                province_id.value = response.data.data.province_id
                city.value = response.data.data.city
                city_id.value = response.data.data.city_id
                postal_code.value = response.data.data.postal_code
                idAddress.value = id
                modalState.value = type

                getCity()
            } catch (error) {
                console.error(error)
            }
        } else if (type == 'add') {
            if (shipping_address.value.length == 0) {
                is_primary.value = 'true'
            } else {
                is_primary.value = 'false'
            }
            modalState.value = 'add'

            address_note.value = ''
            recipient_name.value = ''
            email.value = ''
            phone.value = ''
            address.value = ''
            address_detail.value = ''
            country.value = ''
            province.value = ''
            city.value = ''
            postal_code.value = ''
        } else if (type == 'select') {
            modalState.value = type
        } else if (shipping_address.value.length == 0) {
            modalState.value = 'add'
        } else {
            modalState.value = ''
        }
    }

    const deleteAddress = async (addressId: number) => {
        // Confirm delete
        Swal.fire({
            icon: 'question',
            title: 'Delete Address?',
            showCancelButton: true,
            confirmButtonText: 'Delete'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await window.axios.delete(`/api/address/${addressId}`)
                    getAddresses()
                    Swal.fire({
                        icon: 'success',
                        title: 'Address Deleted!!',
                        showConfirmButton: false,
                        timer: 1500
                    })
                } catch (error: any) {
                    console.error('An error occurred:', error)
                    Swal.fire({
                        icon: 'error',
                        title: "Can't Delete Address",
                        text: error.response.data.message,
                        showConfirmButton: false,
                        timer: 2000
                    })
                }
            }
        })
    }

    return {
        idAddress,
        shipping_note,
        selected_address,
        shipping_address,
        modalState,
        is_primary,
        address_note,
        recipient_name,
        email,
        phone,
        address,
        address_detail,
        country,
        province,
        province_id,
        city,
        city_id,
        postal_code,
        province_list,
        city_list,
        expedition_list,
        selected_expedition,
        shipping_information,
        getShippingInformation,
        getProvince,
        getCity,
        setAddressDefault,
        getAddresses,
        submitAddress,
        form_set,
        updatePrimaryAddress,
        updateSelectedAddress,
        deleteAddress,
        clearAddress
    }
})
