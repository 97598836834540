<template>
    <section class="bg-slate-50">
        <div class="container pt-16">

            <LoadItemText class="font-bold text-2xl mb-6">
                Loading Categories
            </LoadItemText>

            <swiper-container ref="swiperCategories" init="false">
                <swiper-slide v-for="(item, index) in 8" :key="index" class="group">

                    <div
                        class="transition-all duration-500 bg-white rounded-xl p-6 sm:group-[&.swiper-slide-active]:p-1.5 mb-2 border border-transparent shadow group-[&.swiper-slide-active]:border-theme1 group-[&.swiper-slide-active]:shadow-lg group-[&.swiper-slide-active]:shadow-purple-300">
                        <LoadContainer
                            class="aspect-square group-[&.swiper-slide-active]:max-w-full max-w-[100px] transition-all duration-500" />

                        <LoadItem
                            class="h-6 group-[&.swiper-slide-active]:h-0 transition-all duration-500 rounded-md my-2 group-[&.swiper-slide-active]:my-0 max-w-[150px]" />
                        <LoadItem
                            class="h-6 group-[&.swiper-slide-active]:h-0 transition-all duration-500 rounded-md animation-delay-200 max-w-[75px]" />
                    </div>

                </swiper-slide>
            </swiper-container>
        </div>
    </section>
</template>

<script setup lang="ts">
import LoadContainer from './LoadContainer.vue';
import LoadItemText from './LoadItemText.vue';
import LoadItem from './LoadItem.vue';
import { SwiperContainer, register } from 'swiper/element/bundle';
import { SwiperOptions, SwiperModule } from 'swiper/types';
import { onMounted, ref } from "vue";
register();
const swiperCategories = ref<SwiperContainer>();

onMounted(async () => {

    const params: SwiperOptions | null = {
        autoplay: {
            delay: 2000,
        },
        loop: true,
        navigation: {
            nextEl: '.next-category',
            prevEl: '.prev-category',
        },
        spaceBetween: 15,
        slidesPerView: 1,
        breakpoints: {
            640: {
                slidesPerView: 2,
            },
            768: {
                slidesPerView: 3,
            },
            1024: {
                slidesPerView: 4,
            },
        },
        injectStyles: [`.swiper-wrapper { min-height:400px; } `]
    }

    Object.assign(swiperCategories.value as SwiperContainer, params)
    swiperCategories.value?.initialize();
});
</script>