<template>
    <header class="relative pb-12">
        <swiper-container ref="swiperEl" init="false" class="relative lg:h-[500px]">
            <swiper-slide
                class="lg:w-auto h-full lg:scale-[.8] lg:-mx-12 [&.swiper-slide-active]:scale-100 transition-all duration-500 pt-12 px-6"
                v-for="(item, index) in 6" :key="index">
                <LoadContainer class="h-full aspect-[3/4] md:aspect-video bg-slate-100" />
            </swiper-slide>
        </swiper-container>
        <div
            class="prev absolute z-10 left-2 lg:left-6 top-1/2 -translate-y-1/2 flex items-center justify-center w-10 h-10 rounded-full bg-white shadow-md hover:bg-opacity-75 cursor-pointer transition-all backdrop-blur">
            <ChevronLeft />
        </div>
        <div
            class="next absolute z-10 right-2 lg:right-6 top-1/2 -translate-y-1/2 flex items-center justify-center w-10 h-10 rounded-full bg-white shadow-md hover:bg-opacity-75 cursor-pointer transition-all backdrop-blur">
            <ChevronRight />
        </div>
    </header>
</template>

<script setup lang="ts">
import LoadContainer from "./LoadContainer.vue"
import { ChevronLeft, ChevronRight } from "lucide-vue-next";
import { SwiperContainer, register } from 'swiper/element/bundle';
import { SwiperOptions } from 'swiper/types';
import { onMounted, ref } from "vue";
register();

const swiperEl = ref<SwiperContainer>();


onMounted(async () => {

    const params: SwiperOptions | null = {
        autoplay: {
            delay: 2000,
        },
        loop: true,
        navigation: {
            nextEl: '.next',
            prevEl: '.prev',
        },
        pagination: {
            dynamicBullets: true,
        },
        grabCursor: true,
        slidesPerView: "auto",
        slidesPerGroup: 1,
        centeredSlides: true,
        injectStyles: [`
            .swiper-wrapper { align-items:center; }
            .swiper-pagination { margin: 0 0 10px; }
            .swiper-pagination-bullet-active { background: #B370EF; }
        `]
    }


    Object.assign(swiperEl.value as SwiperContainer, params)
    swiperEl.value?.initialize()
});
</script>
