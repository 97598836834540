import axios from 'axios'
import router from './routes'

export default {
    router,
    fetch: axios,
    baseUrl: import.meta.env.VITE_APP_URL,
    fullPathRedirect: true,
    watchLoggedIn: true,
    redirect: {
      login: '/login',
      logout: '/login',
      home: '/',
    },
    local: {
      endpoints: {
        login: { url: '/auth/login', method: 'post' },
        logout: { url: '/logout', method: 'post' },
        user: { url: '/auth/user', method: 'get', propertyName: 'data' },
      },
      token: {
        property: 'token',
        type: 'Bearer',
        name: 'Authorization',
      },
      user: {
        propertyInLogin: 'data',
        propertyInFetch: 'data',
        propertyRole: 'role',
        // propertyPermission: 'permissions',
        autoFetch: true,
      },
    },
  }
