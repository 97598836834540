<template>
    <nav v-if="openMobile"
        class="fixed sm:hidden animate__faster animate__fadeIn top-0 left-0 w-full h-screen flex flex-col items-start justify-start overflow-hidden bg-white z-[99]">
        <div class="flex gap-3 px-6 py-3 flex-col grow w-full shadow-lg shadow-slate-100 ">
            <div class="flex flex-row w-full overflow-hidden items-center justify-start gap-3  ">
                <Avatar class="w-9 h-9 text-sm animate__animated animate__fadeInRight">
                    <AvatarImage :src="imageUrl(user.avatar)" :alt="user.first_name[0] + user.last_name[0]" />
                    <AvatarFallback class="uppercase font-bold">{{ user.first_name[0] + user.last_name[0] }}
                    </AvatarFallback>
                </Avatar>
                <div class="flex flex-col w-full animation-delay-150 animate__animated animate__fadeInUp">
                    <span class="text-sm break-all font-semibold capitalize line-clamp-1">{{ user.first_name + ' ' +
                        user.last_name }}</span>
                    <div class="text-xs text-slate-400">
                        <div class="line-clamp-1 font-normal">
                            {{ user.email }}
                        </div>
                    </div>
                </div>
                <div @click="updateOpenMobile(false)"
                    class="animate__faster animate__zoomIn text-slate-500 flex items-center justify-center rounded-full">
                    <X :size="24" :stroke-width="3" />
                </div>
            </div>
        </div>
        <div class="w-full h-full grow overflow-scroll pt-6 pb-12">
            <div class="px-6">
                <div class="text-sm font-semibold my-2">{{ $t('shipment.page') }}</div>
                <div v-if="primary_address != null"
                    class="relative pt-1 p-4 rounded-lg shadow-lg shadow-slate-100 border-violet-600">
                    <div class="flex flex-row items-center justify-between mb-1">
                        <div
                            class="text-slate-800 text-xs font-medium flex gap-2 items-center justify-center cursor-pointer">
                            {{ $t('shipment.card.type') }}: {{ primary_address.address_note }}
                            <span class="text-green-800 text-xs bg-green-100 p-1 rounded">{{ $t('shipment.card.primary')
                            }}</span>
                        </div>
                    </div>
                    <div class="justify-start items-center flex flex-row flex-wrap gap-2 mb-1.5">
                        <div class="text-slate-800 text-sm font-medium capitalize">
                            {{ $t('shipment.card.receipt') }}: {{ primary_address.recipient_name }}
                        </div>
                        <div class="text-slate-500 text-sm break-all">
                            {{ primary_address.phone }}, {{ primary_address.email }}
                        </div>
                    </div>
                    <div class="text-slate-500 text-xs">
                        {{ $t('shipment.card.detail') }}: {{ primary_address.address }},
                        {{ primary_address.city }},
                        {{ primary_address.province }},
                        {{ primary_address.country }},
                        {{ primary_address.postal_code }}
                    </div>
                </div>
                <hr class="my-4" />
                <div class="text-sm font-semibold my-2">{{ $t('navbar.list') }}</div>
                <div class="flex flex-col gap-3 text-sm font-normal">
                    <RouterLink :to="{
                        name: 'RequestList'
                    }"
                        class="flex flex-row items-center gap-3 shadow-lg shadow-slate-100 text-slate-600 bg-white p-2 justify-start">
                        <ScrollText :size="16" />{{ $t('navbar.dropdown.request') }}
                    </RouterLink>
                    <RouterLink :to="{
                        name: 'ListOrder'
                    }"
                        class="flex flex-row items-center gap-3 shadow-lg shadow-slate-100 text-slate-600 bg-white p-2 justify-start">
                        <Package2 :size="16" />{{ $t('navbar.dropdown.list_order') }}
                    </RouterLink>
                    <RouterLink :to="{
                        name: 'Wishlist'
                    }"
                        class="flex flex-row items-center gap-3 shadow-lg shadow-slate-100 text-slate-600 bg-white p-2 justify-start">
                        <Heart :size="16" />{{ $t('navbar.dropdown.wishlist') }}
                    </RouterLink>
                    <RouterLink :to="{
                        name: 'Profile'
                    }"
                        class="flex flex-row items-center gap-3 shadow-lg shadow-slate-100 text-slate-600 bg-white p-2 justify-start">
                        <UserCog :size="16" />{{ $t('navbar.dropdown.profile') }}
                    </RouterLink>
                    <RouterLink :to="{
                        name: 'Address'
                    }"
                        class="flex flex-row items-center gap-3 shadow-lg shadow-slate-100 text-slate-600 bg-white p-2 justify-start">
                        <MapPinned :size="16" />{{ $t('navbar.dropdown.address') }}
                    </RouterLink>
                    <div @click="logoutOut"
                        class="flex flex-row items-center gap-3 shadow-lg shadow-slate-100 text-slate-600 bg-white p-2 justify-start">
                        <LogOut :size="16" />{{ $t('navbar.dropdown.logout') }}
                    </div>
                </div>
            </div>
        </div>
    </nav>
</template>

<script setup lang="ts">
import { vOnClickOutside } from '@vueuse/components'
//@ts-ignore
import { useAuth } from 'vue3-auth'
import {
    Package2,
    X,
    ScrollText,
    Heart,
    UserCog,
    MapPinned,
    LogOut
} from 'lucide-vue-next'
import { Avatar, AvatarFallback, AvatarImage } from '@/Components/ui/avatar'
import { imageUrl } from '@/helpers'
const { user, logout } = useAuth()

const logoutOut = () => {
    logout();
    window.location.reload();
};

import { ref } from 'vue'
const primary_address = ref<any>(null)

const getAddress = async () => {
    try {
        const { data } = await window.axios.get('/api/address/primary')
        primary_address.value = data.data
    } catch (error) {
        console.log(error)
    }
}

getAddress()

const props = defineProps({
    openMobile: Boolean
})

const emits = defineEmits(['updateOpenMobile'])

const updateOpenMobile = (value: boolean) => {
    emits('updateOpenMobile', value)
}
</script>
